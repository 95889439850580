<template>
    <div>
        <v-dialog v-model="orderDialog" max-width="800" max-height="640" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable @click:outside="orderDialog = false" @keydown.esc="orderDialog = false">
            <v-card>
                <v-card-title class="grey lighten-2">
                    {{ $vuetify.breakpoint.xsOnly ? header.substring(0, 24) + '...' : header }}
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="orderDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'px-1' : 'px-3'">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" sm="7">
                                            <v-text-field v-if="!order.isCombined" readonly :value="order.start_addr" label="Адрес точки (этот адрес видит курьер)" hide-details></v-text-field>
                                            <v-text-field 
                                                v-else 
                                                v-for="childOrder in getPointSequence" 
                                                :key="childOrder.id" 
                                                readonly 
                                                :value="childOrder.start_addr" 
                                                label="Адрес точки (этот адрес видит курьер)" 
                                                hide-details
                                            ></v-text-field>

                                            <v-text-field :readonly="!userOperator && !order.isCombined" v-model="order.finish_addr" label="Адрес гостя (этот адрес видит курьер)" hide-details></v-text-field>
                                            <div class="d-flex flex-row">
                                                <v-text-field v-if="userOperator" :readonly="order.isCombined" class="mr-8 input-price" append-icon="mdi-currency-rub" :value="orderPrice(order.fee_courier)" @input="onInputPriceCourier" label="Цена курьера" hide-details></v-text-field>

                                                <v-text-field class="mr-8 input-price" :readonly="!userOperator || order.isCombined" append-icon="mdi-currency-rub" :value="orderPrice(order.fee_point)" @input="onInputPricePoint" :label="userOperator ? 'Цена точки' : 'Цена доставки'" hide-details></v-text-field>

                                                <v-text-field readonly :value="order.options ? distance(order.options.distance) : 0" label="Расстояние" hide-details></v-text-field>
                                            </div>

                                            <v-row class="mt-0">
                                                <v-col cols="6" sm="7">
                                                    <p v-if="userOperator" class="mb-0 text-caption text--primary">
                                                        Время на забор: <b class="font-weight-medium">{{ timeTake }} м</b>
                                                    </p>
                                                    <p v-if="userOperator" class="mb-2 text-caption text--primary">
                                                        Время на доставку: <b class="font-weight-medium">{{ timeDelivery }} м</b>
                                                    </p>
                                                    <p class="mb-1 text--primary">
                                                        🕓 Создан: <b class="font-weight-medium">{{ timeToString(order.date) }}</b>
                                                    </p>

                                                    <p class="mb-1 text--primary">
                                                        {{ order.mode ? order.mode.split(' ')[0] : '' }} Забрать: <b class="font-weight-medium">до {{ timeToString(order.date_ready) }}</b>
                                                    </p>

                                                    <p class="mb-1 text--primary">
                                                        🏁 Доставить: <b class="font-weight-medium">к {{ timeToString(order.date_delivery) }}</b>
                                                    </p>
                                                </v-col>

                                                <v-col cols="6" sm="5" class="d-flex flex-column align-center justify-center">
                                                    <v-tooltip bottom open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                <v-btn v-if="userOperator && !order.isCombined" :disabled="priceUpdateFlag" :loading="priceUpdateFlag" small class="ma-1 mr-3 btn-update" @click="onClickPriceUpdate" hide-details> 🔍 Найти место </v-btn>
                                                            </span>
                                                        </template>
                                                        <span>Найти место по адресу гостя и обновить стоимость доставки</span>
                                                    </v-tooltip>

                                                    <v-tooltip bottom open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                <v-btn v-if="userOperator" :disabled="priceUpdateFlag || order.status !== config.order.status.new" :loading="priceUpdateFlag" small class="ma-1 mr-3 btn-update" @click="onClickTimesUpdate" hide-details> 🕓 Обновить время </v-btn>
                                                            </span>
                                                        </template>
                                                        <span>Обновить время когда забрать и когда доставить</span>
                                                    </v-tooltip>

                                                    <v-tooltip bottom open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                <v-btn v-if="userOperator && !order.isCombined" :disabled="priceUpdateFlag" small class="ma-1 mr-3 btn-update" @click="onClickDistance" hide-details> 📏 Задать км </v-btn>
                                                            </span>
                                                        </template>
                                                        <span>Задать расстояние вручную и обновить стоимость доставки</span>
                                                    </v-tooltip>

                                                    <v-tooltip bottom open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                <v-btn :disabled="priceUpdateFlag" small class="ma-1 mr-3 btn-update" @click="onClickRoute" hide-details> 🗺 Маршрут </v-btn>
                                                            </span>
                                                        </template>
                                                        <span>Посмотреть маршрут следования курьера</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12" sm="5" class="d-flex align-center justify-center">
                                            <address-input
                                                v-if="showAddress"
                                                :data="addressData"
                                                :center="mapCenter"
                                                :zoom="mapZoom"
                                                :access-point="true"
                                                :self-position="false"
                                                header="Укажите адрес или место доставки"
                                                subtitle=""
                                                @close="showAddress = false"
                                                @input="onAddressInput"
                                                @select="onAddressSelect"
                                            />

                                            <v-row>
                                                <v-col cols="12">
                                                    <span
                                                        class="d-block mb-1"
                                                        :style="{
                                                            color: this.mapPlaceFound ? 'green' : 'red',
                                                        }"
                                                        >{{ this.mapPlaceFound ? '✓ Место доставки определено' : '❗️ Место доставки не определено' }}</span
                                                    >

                                                    <v-tooltip bottom :disabled="!userOperator" open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div id="address-map" :class="{clickable: userOperator}" @click="onMapClick" v-bind="attrs" v-on="on">
                                                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="map-pin" role="img" viewBox="0 0 384 512">
                                                                    <path
                                                                        fill="#7a2a9a"
                                                                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </template>
                                                        <span>Нажмите, чтобы указать на карте место доставки</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="6">
                                    <v-text-field class="mt-2" :readonly="!userOperator || order.isCombined" v-model="order.guest" label="Гость" hide-details></v-text-field>
                                    <v-text-field class="mt-2" :readonly="!userOperator || order.isCombined" v-model="order.pay_cost" label="Цена заказа" hide-details></v-text-field>
                                </v-col>

                                <v-col cols="6" v-if="!order.isCombined">
                                    <v-textarea rows="3" :readonly="!userOperator" v-model="order.descr" label="Комментарий" hide-details></v-textarea>
                                </v-col>

                                <v-col cols="6" v-else>
                                    <v-textarea v-for="childOrder in order.orders" :key='childOrder.id' rows="3" :readonly="!userOperator" v-model="childOrder.descr" :label=setLabel(childOrder) hide-details></v-textarea>
                                </v-col>  

                                <v-col v-if="userOperator && order.courier_tid" cols="12" sm="6">
                                    <v-autocomplete :disabled="order.status !== config.order.status.complete" class="flex-grow-0" no-data-text="Нет данных" :items="couriersList" name="courier" item-text="name" item-value="tid" v-model="order.courier_tid" label="Доставка курьером" persistent-hint hide-details></v-autocomplete>
                                </v-col>

                                <v-col v-if="userOperator && !order.courier_tid && !order.isCombined" cols="12" sm="6">
                                    <v-btn class="mx-2 my-2" color="success" dark @click="dialogCourierComplete = true">Доставка курьером</v-btn>
                                </v-col>

                                <v-col v-if="userOperator" cols="6" sm="3" class="d-flex flex-column justify-center align-end">
                                    <span v-if="order.point_tid"
                                        >Менеджер: <b>{{ order.point_tid }}</b></span
                                    >
                                    <span v-if="order.courier_tid"
                                        >Курьер: <b>{{ order.courier_tid }}</b></span
                                    >
                                </v-col>

                                <v-col v-if="userOperator && !order.isCombined" cols="6" sm="3" class="d-flex flex-column justify-center align-end">
                                    <span v-if="order.id"
                                        >Заказ: <b>{{ order.id }}</b></span
                                    >
                                    <span v-if="order.point_id"
                                        >Точка: <b>{{ order.point_id }}</b></span
                                    >
                                </v-col>

                                <!-- статус {{ order.status }} -->
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <!-- TODO Временно отключена возможность точкам супры создавать заказы, так как курьеры используют кабинет -->

                <v-card-actions>
                        <v-row no-gutters>

                            <v-col cols="6" md="3" class="d-flex align-center justify-center" v-if="!order.isCombined">
                                <v-btn v-if="$store.state.user.settings.city === 'Владивосток' && userOperator && orderPointDarall" :disabled="order.status !== config.order.status.new" class="mx-2 my-2" color="purple" :dark="order.status === config.order.status.new" @click="onClickDarallSend">➡ в Darall</v-btn>
                            </v-col>
                            <v-col cols="6" md="3" class="d-flex align-center justify-center" v-else>
                                <v-btn 
                                    v-if="$store.state.user.settings.city === 'Владивосток' && userOperator && Array.isArray(this.multiOrderPointsDarall) && this.multiOrderPointsDarall.length === this.order.orders.length" 
                                    :disabled="order.status !== config.order.status.new" 
                                    class="mx-2 my-2" color="purple" 
                                    :dark="order.status === config.order.status.new" 
                                    @click="onClickDarallSend">
                                    ➡ в Darall
                                </v-btn>
                            </v-col>
                            <v-col cols="6" md="3" class="d-flex align-center justify-center">
                                <v-btn v-if="userOperator" :disabled="order.status === config.order.status.forming" class="mx-2 my-2" color="success" dark @click="onClickSave">Сохранить</v-btn>
                            </v-col>
                            <v-col cols="6" md="3" class="d-flex align-center justify-center">
                                <v-btn :disabled="($store.state.userIsPoint && $store.state.user.point.name.includes('Супра')) || this.order.isCombined" class="mx-2 my-2" color="purple" text @click="onClickCopy">+ Копировать</v-btn>
                            </v-col>
                            <v-col cols="6" md="3" class="d-flex align-center justify-center">
                                <v-btn v-if="userOperator" :disabled="deleteDisable" class="mx-2" color="error" text @click="onClickDelete">Удалить</v-btn>
                            </v-col>

                        </v-row>

                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCourierComplete" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">Выбор курьера</v-card-title>

                <v-card-text class="py-2">
                    <span>Выберите курьера, который доставил заказ:</span>
                </v-card-text>

                <v-card-text class="py-2">
                    <v-autocomplete :items="couriersList" :value="selectedCourierTid" label="Начните вводить имя или выберите из списка" class="ma-0 pa-0" item-value="tid" item-text="name" persistent-hint return-object single-line hide-details solo @change="onSelectCourier"></v-autocomplete>
                </v-card-text>
            
                <v-card-text class="py-3 d-flex">
                    <v-icon color="orange">mdi-information-outline</v-icon>
                    <span style="color: #ff9800" class="ml-2 my-1 text-caption">Статус заказа изменится на <b>Доставлен</b>.</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" @click="onClickCourierComplete" :disabled="!selectedCourierTid">Принять</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogCourierComplete = false">Отменить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptDelete" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    Удаление заказа
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                        icon
                        @click="promptDelete = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn> -->
                </v-card-title>

                <v-card-text class="py-4">
                    <span>Вы действительно хотите удалить заказ <b>{{ order.name }}</b> ?</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="error" text @click="promptDelete = false">Нет</v-btn>
                    <v-btn class="mx-2" color="success" text @click="promptDeleteYes">Да</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptDistance" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">Изменить расстояние </v-card-title>

                <v-card-text class="py-2">
                    <span>Введите <b>расстояние от точки забора до места доставки в километрах</b>:</span>
                </v-card-text>

                <v-card-text class="py-2">
                    <v-text-field v-model="promptDistanceValue" label="Расстояние, км" hide-details @keydown.enter="promptDistanceYes"></v-text-field>
                </v-card-text>
            
                <v-card-text class="py-3 d-flex">
                    <v-icon color="orange">mdi-information-outline</v-icon>
                    <span style="color: #ff9800" class="ml-2 my-1 text-caption">Стоимость доставки для точки и курьера обновится автоматически.</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" dark @click="promptDistanceYes" :disabled="distanceUpdateFlag" :loading="distanceUpdateFlag">Принять</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="promptDistance = false" :disabled="distanceUpdateFlag">Отменить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Notify from '../../mixins/Notify.js'
    import addressInput from '../AddressInput/Main.vue'

    import * as L from 'leaflet'
    import 'leaflet/dist/leaflet.css'

    export default {
        name: 'dialog-order-edit',
        components: {
            addressInput,
        },
        mixins: [DateTime, Notify],
        props: {
            data: {
                type: Object,
                required: true,
            },
            settings: {
                type: Object,
                required: true,
            },
            courierPrice: {
                type: Number,
                default: 0,
            },
            couriersList: {
                type: Array,
            },
            userOperator: {
                type: Boolean,
                default: false,
            },
            deleteDisable: {
                type: Boolean,
                default: false,
            },
            header: {
                type: String,
                default: '',
            },
        },
        emits: ['save', 'copy', 'delete', 'close', 'darall-send'],
        computed: {
            getPointSequence() {
                const { orders, pointSequence } = this.order

                return pointSequence.map(pointId => {
                    const foundedOrder = orders.find(order => order.pointIdUuid === pointId)

                    if (typeof foundedOrder !== 'undefined') return foundedOrder
                })
            },

            getMultiOrderComment() {
                let multiOrderComment = ''
                const { orders } = this.order

                if (this.order.isCombined && Array.isArray(orders)) {
                    orders.forEach(childOrder => {
                        multiOrderComment += childOrder.descr === '' ? '' : `<b>${childOrder.descr.replace('\n', ' ')}</b><br>`
                    })
                }

                return multiOrderComment
            },
        },
        watch:{
            orderDialog: function(newValue, oldValue) {
                if(!newValue) {
                    this.$emit('close')
                }
            }
        },
        data() {
            return {
                order: null,
                showAddress: false,
                addressData: {
                    address: '',
                    position: null,
                },
                mapCenter: [43.114894, 131.885891], // Владивосток
                mapZoom: 15,
                mapPlaceFound: false,
                promptDelete: false,
                dialogEdit: false,
                dialogCourierComplete: false,
                config,
                timerPriceUpdate: null,
                priceUpdateFlag: false,
                promptDistance: false,
                promptDistanceValue: 0,
                distanceUpdateFlag: false,
                timeTake: 0,
                timeDelivery: 0,
                addressMap: null,
                orderDialog: true,
                orderPointDarall: 0,
                selectedCourierTid: 0,
                manualCompleteFlag: false,
                // массив точек для передачи мультизаказов
                multiOrderPointsDarall: [],
            }
        },
        created() {
            this.order = JSON.parse(JSON.stringify(this.data))
            let coordinates = null
            if (this.order.coordinates) {
                coordinates = JSON.parse(this.order.coordinates)
            }
            console.log('ORDER EDIT name', this.order.name)
            console.log('ORDER EDIT data', this.data)
            console.log('ORDER EDIT order', this.order)
            console.log('ORDER EDIT coordinates', coordinates)
            this.orderTimesReload(this.order)
            setTimeout(() => {
                this.mapCreate(coordinates)
            })

            if (this.order && !this.order.isCombined && this.order.id) {
                this.$store.dispatch('point_get', this.order.point_id).then(res => {
                    if (res.data.success && res.data.point) {
                        console.log('point_get ok', res.data)
                        if (res.data.point.settings) {
                            this.orderPointDarall = res.data.point.settings.darallPoint
                        }
                    }
                })
            } else if (this.order && this.order.isCombined && this.order.id) {
                const { orders } = this.order

                for (let i = 0; i < orders.length; i += 1) {
                    const order = orders[i]

                    this.$store.dispatch('point_get', order.point_id).then(res => {
                    if (res.data.success && res.data.point) {
                        if (res.data.point.settings && res.data.point.settings.darallPoint) {
                            this.multiOrderPointsDarall.push({
                                orderId: order.id,
                                darallPointId: res.data.point.settings.darallPoint,
                                partnerPointId: res.data.point.id,
                            })
                        }

                        console.log('Darall points ids for multiOrder', this.multiOrderPointsDarall)
                    }
                })
                }
            }
            this.disableAppScroll()
        },
        beforeDestroy() {
            this.enableAppScroll()
        },
        mount() {
        },
        methods: {
            setLabel(order) {
                return `Комментарий ${order.start_addr}`
            },

            disableAppScroll() {
                const app = document.getElementById('app')
                if (app) {
                    app.style.overflow = 'hidden'
                }
            },
            enableAppScroll() {
                const app = document.getElementById('app')
                if (app) {
                    app.style.overflow = ''
                }
            },
            onMapClick() {
                if (this.userOperator) {
                    this.showAddress = true
                }
            },

            onAddressSelect(data) {
                if (data && data.position) {
                    this.order.coordinates = JSON.stringify([data.position.lng, data.position.lat])
                    this.mapPlaceFound = true
                } else {
                    this.mapPlaceFound = false
                }

                this.priceUpdate(data.position)
            },

            onAddressInput(value) {},

            mapDefaultPosition() {
                // [lng, lat]
                let position
                if (this.settings.city.includes('Екатеринбург')) {
                    position = [60.60825, 56.839104]
                } else if (this.settings.city.includes('Хабаровск')) {
                    position = [135.084641, 48.482369]
                } else if (this.settings.city.includes('Новосибирск')) {
                    position = [82.955527, 55.026533]
                } else if (this.settings.city.includes('Ижевск')) {
                    position = [53.206896, 56.852677]
                } else if (this.settings.city.includes('Москва')) {
                    position = [37.617700, 55.755863]
                } else if (this.settings.city.includes('Благовещенск')) {
                    position = [127.533373, 50.2876389]
                } else if (this.settings.city.includes('Краснодар')) {
                    position = [38.985679, 45.066115]
                } else if (this.settings.city.includes('Находка')) {
                    position = [132.807147, 42.828675]
                } else if (this.settings.city.includes('Уссурийск')) {
                    position = [132.002090, 43.792126]
                } else if (this.settings.city.includes('Южно-Сахалинск')) {
                    position = [142.743736, 46.963269]
                } else if (this.settings.city.includes('Череповец')) {
                    position = [37.916389, 59.128696]
                } else {
                    // Владивосток
                    position = [131.885891, 43.114894]
                }
                return position
            },

            mapCreate(center) {
                console.log('mapCreate', center, this.addressMap)
                if (!this.addressMap) {
                    this.mapZoom = 17
                    if (center && center.length == 2) {
                        this.mapPlaceFound = true
                    } else {
                        this.mapPlaceFound = false
                        center = this.mapDefaultPosition()
                        this.mapZoom = 14
                    }
                    center = {
                        lat: +center[1],
                        lng: +center[0],
                    }
                    console.log('center after', center)
                    this.addressData.position = center

                    this.addressMap = new L.map('address-map', {
                        center,
                        zoom: this.mapZoom,
                        dragging: false,
                        scrollWheelZoom: false,
                        zoomControl: false,
                        doubleClickZoom: false,
                        attributionControl: false,
                    })

                    L.tileLayer(config.map.url, {
                        attributionControl: false,
                        maxZoom: 20,
                        minZoom: 13,
                        retina: '@2x',
                        detectRetina: true,
                    }).addTo(this.addressMap)
                } else {
                    this.mapUpdate(center)
                }
            },

            mapUpdate(position) {
                console.log('mapUpdate', position)
                this.mapInvalidateSize()
                this.mapZoom = 17
                if (position && position.length == 2) {
                    this.mapPlaceFound = true
                } else {
                    this.mapPlaceFound = false
                    position = this.mapDefaultPosition()
                    this.mapZoom = 14
                }
                position = {
                    lat: +position[1],
                    lng: +position[0],
                }
                this.addressData.position = position
                this.addressMap.setView(position, this.mapZoom)
            },

            mapInvalidateSize(s) {
                if (this.addressMap) this.addressMap.invalidateSize(false)
            },

            orderTimesReload(order) {
                const distance = order.options.distance ?? 0

                this.timeTake = 30 // 30 минут
                this.timeDelivery = 30 // 30 минут

                if (this.settings.timeTake) {
                    this.timeTake = this.settings.timeTake
                }
                if (this.settings.timeDelivery) {
                    this.timeDelivery = this.settings.timeDelivery
                }

                if (this.settings.timesByDistance) {
                    if (this.settings.distanceTimes && this.settings.distanceTimes.length) {
                        for (let times_value of this.settings.distanceTimes) {
                            if (distance >= times_value[0]) {
                                this.timeTake = times_value[1]
                                this.timeDelivery = times_value[2]
                            }
                        }
                    }
                }
            },
            priceUpdate(position) {
                this.priceUpdateFlag = true
                let point_id = this.order.point_id
                let params
                if (position) {
                    params = { point_id, lat: position.lat, lng: position.lng }
                } else {
                    params = { point_id, address: this.order.finish_addr }
                }
                this.$store
                    .dispatch('point_address', params)
                    .then(res => {
                        if (res.data.success) {
                            console.log('point_address ok', res.data)
                            this.notifyToast('Адрес и стоимость доставки обновлены успешно', { color: 'success', timeout: 4000 })
                        } else {
                            console.log('point_address fail')
                            this.notifyToast('Не удалось обновить - не найден адрес', { color: 'error', timeout: 4000 })
                        }

                        this.order.fee_point = +res.data.price.point
                        this.priceUpdateFlag = false
                        if (this.courierPrice > 0) {
                            this.order.fee_courier = this.courierPrice
                        } else {
                            this.order.fee_courier = +res.data.price.courier
                        }

                        this.order.coordinates = JSON.stringify(res.data.coordinates)
                        if (!this.order.coordinates) this.order.coordinates = null

                        this.order.options.distance = res.data.distance

                        console.log('this.order after', this.order)

                        this.mapUpdate(res.data.coordinates)
                        this.orderTimesReload(this.order)
                    })
                    .catch(err => {
                        console.log(err)
                        this.priceUpdateFlag = false
                        this.notifyToast('Не удалось обновить - ошибка обновления', { color: 'error', timeout: 4000 })
                    })
            },
            onInputPriceCourier(value) {
                console.log('onChangePriceCourier', value)
                if (!value) value = 0
                this.order.fee_courier = parseInt(value)
            },

            onInputPricePoint(value) {
                if (!value) value = 0
                this.order.fee_point = parseInt(value)
            },

            onClickPriceUpdate() {
                if (this.timerPriceUpdate) {
                    clearTimeout(this.timerPriceUpdate)
                    this.timerPriceUpdate = null
                }
                this.order.fee_courier = 'Поиск...'
                this.order.fee_point = 'Поиск...'
                this.order.options.distance = 'Поиск...'
                this.priceUpdate()
            },

            onClickTimesUpdate() {
                const order = this.order

                if (order.mode == '🔥 Ближайший') {
                    const dateReady = moment()
                    dateReady.add(this.timeTake, 'm')
                    order.date_ready = dateReady.utc().format()
                    const dateDelivery = dateReady
                    dateDelivery.add(this.timeDelivery, 'm')
                    order.date_delivery = dateDelivery.utc().format()
                } else {
                    const dateReady = moment(order.date_delivery)
                    dateReady.subtract(this.timeDelivery, 'm')
                    order.date_ready = dateReady.utc().format()
                }
                this.notifyToast('Времена обновлены успешно', { color: 'success', timeout: 4000 })
            },

            onClickRoute() {
                if (this.order.isCombined) this.$store.dispatch('open_multi_route', this.order)
                else this.$store.dispatch('open_route', this.order)
            },

            onClickDistance() {
                this.promptDistanceValue = this.distanceNumber(this.order.options.distance)
                console.log('this.order.options.distance', this.order.options.distance)
                console.log('this.promptDistanceValue', this.promptDistanceValue)
                this.promptDistance = true
            },

            promptDistanceYes() {
                const distance = this.promptDistanceValue * 1000

                if (distance) {
                    this.distanceUpdateFlag = false
                    this.$store
                        .dispatch('price_distance', { distance })
                        .then(res => {
                            if (res.data.success) {
                                console.log('price_distance ok', res.data)
                                this.order.fee_courier = +res.data.priceCourier
                                this.order.fee_point = +res.data.pricePoint
                                this.order.options.distance = distance
                                this.promptDistance = false
                                this.notifyToast('Стоимость доставки по расстоянию обновлена успешно', { color: 'success', timeout: 4000 })
                            } else {
                                console.log('price_distance fail')
                                this.notifyToast('Не определена стоимость доставки. ERROR: ' + res.data.msg, { color: 'error', timeout: 4000 })
                            }
                            this.distanceUpdateFlag = false
                        })
                        .catch(err => {
                            console.log(err)
                            this.distanceUpdateFlag = false
                        })
                } else {
                    this.notifyToast('Введите значение расстояния в километрах', { color: 'error', timeout: 4000 })
                }
            },

            promptDeleteYes() {
                this.$emit('delete')
                this.$emit('close')
            },

            onClickClose() {
                this.$emit('close')
            },

            onClickDelete() {
                this.promptDelete = true
            },

            onClickSave() {
                this.$emit('save', { order: this.order, manualCompleteFlag: this.manualCompleteFlag } )
                this.$emit('close')
            },

            onClickCopy() {
                this.$emit('copy', this.order)
                this.$emit('close')
            },

            onClickDarallSend() {
                if (!this.order.isCombined) this.$emit('darall-send', { order: this.order, orderPointDarall: this.orderPointDarall })
                else this.$emit('darall-send', { order: this.order, multiOrderPointsDarall: this.multiOrderPointsDarall })         
            },

            onSelectCourier(item) {
                console.log('item', item)
                console.log('selectedCourierTid', this.selectedCourierTid)
                this.selectedCourierTid = item.tid
            },
            
            onClickCourierComplete() {
                this.dialogCourierComplete = false
                this.order.courier_tid = this.selectedCourierTid
                this.order.status = config.order.status.complete
                this.manualCompleteFlag = true
            },

            feeUpdate() {
                this.loading = true
                this.$store
                    .dispatch('orders_fee_calculate', {
                        date1: this.d1,
                        date2: this.d2,
                        // mode: 0,
                    })
                    .then(res => {
                        this.loading = false
                        if (res.data.success) {
                            this.updateData()
                        }
                    })
                    .catch(err => {
                        console.log('2 error', err.name, err.message)
                        this.loading = false
                    })
            },

            distance(d) {
                if (!d) d = 0
                // console.log('distance', d)
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1) + ' км'
                }
                return '--'
            },

            orderPrice(p) {
                if (!p) p = 0
                const price = parseInt(p)
                if (price) {
                    return price
                }
                return '--'
            },

            distanceNumber(d) {
                if (!d) d = 0
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1)
                }
                return 0
            },
        },
    }
</script>

<style lang="stylus">
    .input-price .mdi-currency-rub {
        font-size: 18px !important;
    }
    // .input-price .v-input__prepend-outer {
    //     margin-right: 0 !important;
    // }
</style>


<style lang="stylus" scoped>

    .input-price {
        width: 140px;
    }

    .order-dialog
        height 100%
        padding-top 10px !important

    .btn-update {
        height: 24px !important;
        min-width: 168px !important;
    }

    .map-pin {
        width: 30px !important;
        height: 30px !important;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 30px;
        right: 0;
        z-index: 401;
    }
    #address-map {
        height: 220px;
        box-shadow 0px 0px 2px #aaa, 1px 1px 2px #aaa
    }
    #address-map.clickable {
        cursor: pointer;
    }
    #address-map.clickable:hover {
        box-shadow 0px 0px 2px grey, 1px 1px 4px grey
    }
</style>
