<template>
    <div>
        <v-dialog v-model="orderDialog" max-width="800" max-height="640" :persistent="addressValid()" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable @click:outside="onClickClose" @keydown.esc="onClickClose">
            <v-card :loading="loadingOrderDialog">
                <v-card-title class="grey lighten-2">
                    {{ header }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="onClickClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pa-0 ma-0">

                    <v-stepper v-model="orderStep" non-linear tile vertical elevation="0" light @change="onChangeStepper">
                        <v-stepper-step :complete="true" step="1" :editable="true">
                            Адрес точки, откуда забрать
                            <br />
                            <span class="step-info"
                                ><b>{{ orderPointAddress }}</b></span
                            >
                        </v-stepper-step>

                        <v-stepper-content step="1">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-left pb-0">
                                    <span>Выберите точку, откуда нужно забрать заказ:</span>
                                </v-col>

                                <v-col cols="7" class="text-center">
                                    <v-autocomplete :items="points" :value="selectedPointId" label="Начните вводить или выберите из списка" class="ma-0 pa-0 input-point" item-value="id" item-text="name" persistent-hint return-object single-line hide-details solo @change="pointSelect"></v-autocomplete>
                                </v-col>

                                <v-col cols="5" class="text-center d-flex align-center justify-center">
                                    <v-btn color="primary" class="mx-1" :disabled="!order.start_addr" @click="orderStep = 2">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>


                        <v-stepper-step :complete="addressValid() && order.point_id > 0" step="2" :editable="addressValid() && order.point_id > 0">
                            Адрес гостя, куда доставить
                            <br />
                            <span class="step-info"
                                ><b>{{ getAddress() }}</b></span
                            >
                        </v-stepper-step>

                        <v-stepper-content step="2">
                            <address-input v-if="showAddress" :data="addressData" :center="mapCenter" :zoom="mapZoom" :access-point="true" :self-position="false" header="Укажите адрес или место доставки" subtitle="" @close="showAddress = false" @input="onAddressInput" @select="onAddressSelect" />

                            <v-row class="stepper-content">
                                <v-col cols="12" sm="6">
                                    <span>Нажмите на карту, чтобы указать место доставки:</span>

                                    <v-tooltip bottom open-delay="800">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div id="address-map" class="clickable" @click="onMapClick" v-bind="attrs" v-on="on">
                                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="map-pin" role="img" viewBox="0 0 384 512">
                                                    <path
                                                        fill="#7a2a9a"
                                                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                                                    />
                                                </svg>
                                            </div>
                                        </template>
                                        <span>Нажмите, чтобы указать на карте место доставки</span>
                                    </v-tooltip>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-row>
                                        <v-col cols="12" class="text-center">
                                            <v-text-field :disabled="!mapPlaceFound" v-model="order.finish_addr" :label="addressWrong() ? 'Впишите сюда адрес' : 'Адрес'" hide-details :error="addressWrong()"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" class="pb-0">
                                            <span :class="{'grey--text': !mapPlaceFound}">Укажите дополнительную информацию по адресу:</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field :disabled="!mapPlaceFound" v-model="addressAdditions.flat" label="Квартира" hide-details dense></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field :disabled="!mapPlaceFound" v-model="addressAdditions.entrance" label="Подъезд" hide-details dense></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field :disabled="!mapPlaceFound" v-model="addressAdditions.floor" label="Этаж" hide-details dense> </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field :disabled="!mapPlaceFound" v-model="addressAdditions.other" label="Другое" hide-details dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mt-2 mx-1" @click="orderStep = 1">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" :disabled="!addressValid()" class="mt-2 mx-1" @click="orderStep = 3">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>


                        <v-stepper-step :complete="this.order.mode ? true : false" step="3" :editable="copyFlag || this.order.mode ? true : false">
                            Время доставки
                            <br />
                            <span v-if="isTimeReady" class="step-info" v-html="getTimes()"></span>
                        </v-stepper-step>

                        <v-stepper-content step="3">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-center">
                                    <div class="mb-4">
                                        <span>Выберите тип заказа:</span><br />
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="dialogTypeNearest = true"> 🔥 Ближайший </v-btn>
                                        <v-btn
                                            outlined
                                            text
                                            class="mx-1 mt-1 keyboard"
                                            @click="
                                                dialogTypePreorder = true
                                                preorderStep = 1
                                            "
                                        >
                                            ⏰ Предзаказ
                                        </v-btn>
                                    </div>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mx-1" @click="orderStep = 2">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" class="mx-1" :disabled="!order.mode" @click="orderStep = 4">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>


                        <v-stepper-step :complete="order.guest ? true : false" step="4" :editable="copyFlag || order.guest ? true : false">
                            Имя и телефон гостя
                            <br />
                            <span class="step-info"
                                ><b>{{ order.guest ? order.guest : '' }}</b></span
                            >
                        </v-stepper-step>

                        <v-stepper-content step="4">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-left ml-3">
                                    <span>Укажите имя и телефон гостя, например, "Анна +79023456780":</span>
                                    <v-text-field autofocus style="width: 400px" v-model="order.guest" flat hide-details> </v-text-field>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mx-1" @click="orderStep = 3">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" class="mx-1" :disabled="!order.guest" @click="orderStep = 5">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>


                        <v-stepper-step :complete="orderPayCostValid() === true" step="5" :editable="copyFlag || orderPayCostValid() === true">
                            Оплата
                            <br />
                            <span class="step-info" v-html="orderPayCost()"></span>
                        </v-stepper-step>

                        <v-stepper-content step="5">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-center">
                                    <div class="mb-4">
                                        <span>Выберите тип оплаты:</span><br />
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('✅ Оплачено')"> ✅ Оплачено </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('🆗 Без сдачи')"> 🆗 Без сдачи </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('🔸 Сдача')"> 🔸 Сдача </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('↔️ Перевод')"> ↔️ Перевод </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('📸 QR/ссылка')"> 📸 QR/ссылка </v-btn>
                                        <v-btn outlined text class="mx-1 mt-1 keyboard" @click="onClickOrderPay('💳 Безнал')"> 💳 Безнал </v-btn>
                                    </div>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mx-1" @click="orderStep = 4">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" class="mx-1" :disabled="!orderPayCostValid()" @click="orderStep = 6">
                                        Далее
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>


                        <v-stepper-step :complete="orderStepLast" step="6" :editable="copyFlag || orderPayCostValid() === true">
                            Комментарий
                            <br />
                            <span :class="orderDescrEmpty() ? 'step-empty' : 'step-info'" v-html="orderDescrEmpty() ? 'не задан' : orderDescr()"></span>
                        </v-stepper-step>

                        <v-stepper-content step="6">
                            <v-row class="stepper-content">
                                <v-col cols="12" class="text-left">
                                    <div class="mb-4">
                                        <v-textarea autofocus rows="3" v-model="order.descr" label="Введите комментарий (примечание от гостя или заведения)" hide-details> </v-textarea>
                                    </div>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" outlined class="mx-1" @click="orderStep = 5">
                                        <v-icon>mdi-chevron-left</v-icon>
                                        Назад
                                    </v-btn>
                                    <v-btn color="primary" class="mx-1" :disabled="!orderDescrValid()" @click="onClickOrderDescrSelect">
                                        {{ order.descr && order.descr.length ? 'Готово' : 'Пропустить' }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper>

                    <v-row no-gutters class="justify-end pb-2 px-6 blue-grey--text font-weight-regular">
                        <span v-if="orderValid()">* Проверьте данные заказа и нажмите <b>"Создать заказ"</b></span>
                        <span v-else>* Заполните обязательные поля для нового заказа</span>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="mx-2">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="5" class="d-flex align-center">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    Стоимость доставки:
                                    <span v-html="orderDeliveryFee()" :class="addressValid() ? 'step-info' : ''"></span>
                                </v-col>
                                <v-col cols="12">
                                    Общее время доставки:
                                    <span v-html="orderDeliveryTime()" :class="this.order.mode ? 'step-info' : ''"></span>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" sm="6" md="7">
                            <v-row no-gutters>

                                <v-col cols="12" md="6" class="d-flex justify-center">
                                    <v-tooltip v-if="addressValid()" top open-delay="800">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                <v-btn class="mx-2 my-1" outlined color="primary" @click="onClickRoute" hide-details> 🗺 Маршрут {{ order.options && order.options.distance ? distanceString(order.options.distance) : '' }} </v-btn>
                                            </span>
                                        </template>
                                        <span>Посмотреть маршрут следования курьера</span>
                                    </v-tooltip>
                                </v-col>

                                <v-col cols="12" md="6" class="d-flex justify-center">
                                    <v-btn class="mx-2 my-1" color="success" :disabled="!orderValid() || !isTimeReady" @click="onClickCreate">Создать заказ </v-btn>
                                </v-col>

                            </v-row>
                        </v-col>

                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogTypePreorder" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    ⏰ Предзаказ {{ preorderStep == 2 ? 'на ' + getDayStr(preorderDay) : '' }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogTypePreorder = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text v-if="preorderStep == 1" class="py-4">
                    <p><b>Шаг 1</b>. Выберите день, <b>когда нужно доставить</b>:</p>
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickPreorderDay(0)">{{ getDayStr(0) }} </v-btn>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="dialogTypePreorder = false">⬅️ Назад </v-btn>
                        </v-col>
                    </v-row>

                    <v-row v-for="i in Math.round((1 + preorderDays.length) / 3)" :key="i">
                        <v-col v-for="j in 3" :key="j" cols="4" class="pa-1">
                            <v-btn block outlined text :disabled="(i - 1) * 3 + j > preorderDays.length" class="keyboard" @click="onClickPreorderDay((i - 1) * 3 + j)"> {{ getDayStr((i - 1) * 3 + j) }}</v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-card-text v-if="preorderStep == 2" class="py-4">
                    <p><b>Шаг 2</b>. Укажите время, <b>во сколько будет готов</b>:</p>
                    <v-row>
                        <v-col cols="3" class="pa-3">
                            <v-text-field autofocus v-model="preorderTimeReady" type="time" dense hide-details @keydown.enter="preorderNext"></v-text-field>
                        </v-col>
                        <v-col cols="3" class="pa-3 mt-1"> </v-col>
                        <v-col cols="12" class="d-flex justify-end">
                            <v-btn text color="primary" class="mx-1" @click="preorderStep -= 1"> <v-icon>mdi-chevron-left</v-icon> Назад </v-btn>
                            <v-btn color="primary" :disabled="!preorderTimeReady" class="mx-1" @click="preorderNext"> Далее <v-icon>mdi-chevron-right</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text v-if="preorderStep == 3" class="py-4">
                    <v-row>
                        <v-col cols="12">
                            <p><b>Шаг 3</b>. Выберите <b>диапазон</b> или <b>точное время</b> доставки:</p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header v-slot="{ open }" @click="preorderTimeDeliveryMode = 1">
                                    <v-row no-gutters>
                                        <v-col cols="8"> 🕓 Точное время </v-col>

                                        <v-col cols="4" class="text--secondary">
                                            <v-fade-transition leave-absolute>
                                                <v-row v-if="!open" no-gutters style="width: 100%">
                                                    <v-col cols="12">
                                                        {{ preorderTimeDeliveryMode == 1 ? preorderTimeDeliveryFrom || '' : '' }}
                                                    </v-col>
                                                </v-row>
                                            </v-fade-transition>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <span v-bind="attrs" v-on="on">Укажите время, <b>к которому нужно доставить</b></span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6" sm="3">
                                                            <v-text-field autofocus v-model="preorderTimeDeliveryFrom" type="time" dense hide-details @keydown.enter="onClickPreorderTimeDelivery"> </v-text-field>
                                                        </v-col>
                                                        <v-col cols="6" sm="9" class="mt-1 d-flex justify-center">
                                                            <v-btn color="primary" class="mx-1" :disabled="preorderTimeDeliveryDisable()" @click="onClickPreorderTimeDelivery">Далее <v-icon> mdi-chevron-right</v-icon> </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header v-slot="{ open }" @click="preorderTimeDeliveryMode = 2">
                                    <v-row no-gutters>
                                        <v-col cols="8"> ⏳ Диапазон </v-col>

                                        <v-col cols="4" class="text--secondary">
                                            <v-fade-transition leave-absolute>
                                                <v-row v-if="!open" no-gutters style="width: 100%">
                                                    <v-col cols="12">
                                                        {{ preorderTimeDeliveryMode == 2 ? (preorderTimeDeliveryFrom && preorderTimeDeliveryTo ? preorderTimeDeliveryFrom + ' - ' + preorderTimeDeliveryTo : '') : '' }}
                                                    </v-col>
                                                </v-row>
                                            </v-fade-transition>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <span v-bind="attrs" v-on="on">Укажите диапазон времени, <b>в который нужно доставить</b></span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">
                                                            от:
                                                            <v-text-field autofocus v-model="preorderTimeDeliveryFrom" type="time" dense hide-details></v-text-field>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            до:
                                                            <v-text-field v-model="preorderTimeDeliveryTo" type="time" dense hide-details @keydown.enter="onClickPreorderTimeDelivery"> </v-text-field>
                                                        </v-col>
                                                        <!-- <v-col cols="1" class="mt-5"></v-col> -->
                                                        <v-col cols="6" class="mt-5 d-flex justify-center">
                                                            <v-btn color="primary" class="mx-1" :disabled="preorderTimeDeliveryDisable()" @click="onClickPreorderTimeDelivery">Далее <v-icon> mdi-chevron-right</v-icon> </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <v-btn text color="primary" class="mx-1" @click="preorderStep -= 1"> <v-icon>mdi-chevron-left</v-icon> Назад </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPayCost" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    {{ order.pay }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogPayCost = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-6">
                    <v-row>
                        <v-col cols="12" class="pa-0 mx-2">
                            <p>Укажите <b>сумму заказа</b> (не менее 100 ₽):</p>
                        </v-col>
                        <v-col cols="6" class="pa-0 mx-2">
                            <v-text-field class="input-cost" append-icon="mdi-currency-rub" type="number" v-model="order.pay_cost" autofocus dense hide-details @keydown.enter="orderPayCostNext"> </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="pa-3 mt-1 d-flex">
                            <v-spacer></v-spacer>
                            <!-- <v-btn text color="primary" class="mx-1" @click="dialogPayCost = false"> <v-icon>mdi-chevron-left</v-icon> Назад </v-btn> -->
                            <v-btn color="primary" class="mx-1" :disabled="!orderPayCostValid()" @click="orderPayCostNext">Выбрать <v-icon>mdi-chevron-right</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogTypeNearest" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    🔥 Ближайший
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogTypeNearest = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="py-4">
                    <p>Выберите <b>через сколько будет готов</b>:</p>
                    <v-row>
                        <v-col cols="6" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(0)">✅ Уже готов </v-btn>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="dialogTypeNearest = false">⬅️ Назад </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(5)">5 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(10)">10 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(15)">15 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(20)">20 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(25)">25 мин</v-btn>
                        </v-col>
                        <v-col cols="4" sm="2" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(30)">30 мин</v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="3" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(40)">40 мин</v-btn>
                        </v-col>
                        <v-col cols="6" sm="3" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(50)">50 мин</v-btn>
                        </v-col>
                        <v-col cols="6" sm="3" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(60)">1 час</v-btn>
                        </v-col>
                        <v-col cols="6" sm="3" class="pa-1">
                            <v-btn block outlined text class="keyboard" @click="onClickNearestTime(90)">1 час 30 мин </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptClose" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    Отмена создания заказа
                    <!-- <v-spacer></v-spacer>
                    <v-btn icon @click="promptClose = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn> -->
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="py-4">
                    Вы действительно хотите <b>отменить создание заказа</b>?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="error" text @click="promptClose = false">Нет</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="promptCloseYes">Да</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Notify from '../../mixins/Notify.js'
    import addressInput from '../AddressInput/Main.vue'

    import * as L from 'leaflet'
    import 'leaflet/dist/leaflet.css'

    export default {
        name: 'dialog-order-create',

        components: {
            addressInput,
        },

        mixins: [DateTime, Notify],

        props: {
            copyData: {
                type: Object,
            },
            settings: {
                type: Object,
                required: true,
            },
            courierPrice: {
                type: Number,
                default: 0,
            },
            points: {
                type: Array,
                default: null,
            },
            userOperator: {
                type: Boolean,
                default: false,
            },
            header: {
                type: String,
                default: '',
            },
        },

        emits: ['close', 'create'],

        watch: {
            orderDialog: function (newValue, oldValue) {
                if (!newValue) {
                    this.$emit('close')
                }
            },
        },

        computed: {
            orderDeliveryFeeClass: function () {
                return {
                    'step-info': this.addressValid() ? true : false,
                }
            },

            orderDeliveryTimeClass: function () {
                return {
                    'step-info': this.order.mode ? true : false,
                }
            },
        },

        data() {
            return {
                orderStep: 0,
                preorderStep: 0,
                order: {
                    descr: '',
                    finish_addr: '',
                },
                showAddress: false,
                addressData: {
                    address: '',
                    position: null,
                },
                mapCenter: [43.114894, 131.885891], // Владивосток
                mapZoom: 15,
                mapPlaceFound: false,
                dialogEdit: false,
                config,
                timerPriceUpdate: null,
                addressMap: null,
                orderDialog: false,
                addressAdditions: {},
                promptClose: false,
                dialogTypeNearest: false,
                dialogTypePreorder: false,
                preorderDay: 0,
                preorderDays: [],
                preorderTimeReady: '',
                preorderTimeDelivery: '',
                preorderTimeDeliveryFrom: '',
                preorderTimeDeliveryTo: '',
                preorderTimeDeliveryMode: 0,
                dialogPayCost: false,
                addressEmptySelect: false,
                orderDescrSelect: false,
                orderStepLast: false,
                orderPointAddress: '',
                pointPrice: 0,
                timeTake: 1800, // 30 мин
                timeDelivery: 1800, // 30 мин
                orderAddDescr: null,
                promptCloseFlag: false,
                selectedPointId: 0,
                loadingOrderDialog: false,
                isTimeReady: false,
                copyFlag: this.copyData ? true : false,
                holidays: [
                    {
                        name: 'День Святого Валентина',
                        icon: '💕',
                        date: '14 фев.',
                        daysCount: 1,
                    },
                    {
                        name: 'День Защитника Отечества',
                        icon: '🛡',
                        date: '23 фев.',
                        daysCount: 1,
                    },
                    {
                        name: '8 Марта',
                        icon: '🌷',
                        date: '8 мар.',
                        daysCount: 1,
                    },
                    {
                        name: '1 Мая - праздник Весны и Труда',
                        icon: '🎈',
                        date: '1 мая',
                        daysCount: 1,
                    },
                    {
                        name: 'День защиты детей',
                        icon: '👶',
                        date: '1 июн.',
                        daysCount: 1,
                    },
                    {
                        name: 'День России',
                        icon: '🇷🇺',
                        date: '12 июн.',
                        daysCount: 1,
                    },
                    {
                        name: '1 Сентября',
                        icon: '📚',
                        date: '1 сент.',
                        daysCount: 1,
                    },
                    {
                        name: 'Новый Год',
                        icon: '🎅🏼',
                        date: '31 дек.',
                        daysCount: 7,
                    },
                ],
            }
        },

        created() {
            // console.log('this.points', this.points)
            if (this.points && this.points.length) {
                this.preorderDaysGenerate()
                console.log('this.preorderDays', this.preorderDays)
                this.orderDialog = true
            } else {
                this.notifyToast('Невозможно создать заказ - нет ни одной точки', { color: 'error', timeout: 5000 })
                this.$emit('close')
            }
            // console.log('points', this.points)

            setTimeout(() => {
                this.mapCreate()

                this.orderStep = 1

                if (this.copyData) {
                    this.order = this.copyData
                    const point = this.points.find(p => p.id === this.copyData.point_id)
                    if (point) {
                        this.pointSelect(point)
                    }

                    console.log('copy order', this.copyData)

                    if (this.copyData.coordinates) {
                        const coordinates = JSON.parse(this.copyData.coordinates)
                        if (coordinates.length === 2) {
                            this.mapUpdate(coordinates)
                            this.mapPlaceFound = true
                            this.promptCloseFlag = false

                            this.priceUpdate({
                                lng: coordinates[0],
                                lat: coordinates[1],
                            })
                        }
                    }

                    this.addressData.address = this.copyData.finish_addr
                    this.selectedPointId = this.copyData.point_id

                    this.order = {
                        point_id: this.copyData.point_id,
                        start_addr: this.copyData.start_addr,
                        finish_addr: this.copyData.finish_addr,
                        coordinates: this.copyData.coordinates,
                        fee_point: this.copyData.fee_point,
                        fee_courier: this.copyData.fee_courier,
                        guest: this.copyData.guest,
                        pay: this.copyData.pay,
                        pay_cost: this.copyData.pay_cost,
                        options: this.copyData.options,
                        date_take: null,
                        date_complete: null,
                    }

                    this.order.mode = this.copyData.mode
                    this.order.date = moment()

                    if (this.copyData.mode == '🔥 Ближайший') {
                        const regexp = /^готовность: (\d+ мин|уже готов)[,]? ?/
                        this.order.descr = this.copyData.descr.replace(regexp, '')
                        const timeMatch = this.copyData.descr.match(regexp)

                        console.log('timeStr', timeMatch)
                        let time = 0

                        if (timeMatch && timeMatch[1]) {
                            time = parseInt(timeMatch[1], 10)
                            console.log('time', time)
                            if (!time) {
                                time = 0
                            }
                        }
                        this.nearestTimeUpdate(time)
                    } else {
                        this.order.descr = this.copyData.descr
                        this.order.date_ready = this.copyData.date_ready
                        this.order.date_delivery = this.copyData.date_delivery

                        this.preorderTimeReady = moment(this.order.date_ready).format('HH:mm')
                        this.preorderTimeDeliveryFrom = moment(this.order.date_delivery).format('HH:mm')
                        this.preorderTimeDeliveryTo = moment(this.order.date_delivery).format('HH:mm')

                        this.isTimeReady = true
                    }

                    this.onClickOrderDescrSelect()
                } else {
                    if (this.points.length == 1) {
                        this.selectedPointId = this.points[0].id
                        this.pointSelect(this.points[0])
                        this.orderStep += 1
                    }
                    this.isTimeReady = true
                }
            })
            this.disableAppScroll()
        },
        beforeDestroy() {
            this.enableAppScroll()
        },

        methods: {
            nearestTimeUpdate(time) {
                const interval = setInterval(() => {
                    if (!this.loadingOrderDialog) {
                        clearInterval(interval)
                        this.onClickNearestTime(time)
                        this.isTimeReady = true
                    }
                }, 1000)
            },
            disableAppScroll() {
                const app = document.getElementById('app')
                if (app) {
                    app.style.overflow = 'hidden'
                }
            },
            enableAppScroll() {
                const app = document.getElementById('app')
                if (app) {
                    app.style.overflow = ''
                }
            },
            addressWrong() {
                return this.order.finish_addr == '' && this.addressEmptySelect
            },

            addressValid() {
                return this.copyFlag || (this.order && this.order.finish_addr !== undefined && this.order.finish_addr !== '' && this.mapPlaceFound)
            },

            onAddressSelect(data) {
                console.log('onAddressInput', data)

                if (data && data.position) {
                    const coordinates = [data.position.lng, data.position.lat]
                    this.order.coordinates = JSON.stringify(coordinates)
                    this.mapUpdate(coordinates)
                    this.promptCloseFlag = true
                    this.mapPlaceFound = true
                } else {
                    this.mapPlaceFound = false
                }

                this.priceUpdate(data.position)

                if (data.address == '') {
                    this.addressEmptySelect = true
                } else {
                    this.order.finish_addr = data.address
                    this.addressData.address = data.address
                }
            },

            onClickRoute() {
                this.$store.dispatch('open_route', this.order)
            },

            orderDeliveryFee() {
                return this.pointPrice ? `<b>${this.pointPrice} ₽</b>` : '--'
            },

            orderDeliveryTime() {
                let dateBegin
                if (this.order.mode === '⏰ Предзаказ') {
                    dateBegin = this.order.date_ready
                } else {
                    dateBegin = this.order.date
                }
                return this.order.mode && this.order.date_delivery ? `<b>${this.timeDiff(dateBegin, this.order.date_delivery, false)}</b>` : '--'
            },

            onChangeStepper(event) {
                // console.log('onChangeStepper', event, this.orderStep)
                // if (this.orderStep) {
                //     setTimeout(() => {
                //         this.orderStep = 0
                //     }, 10)
                // }
            },

            pointSelect(item) {
                // console.log('pointSelect', item)
                this.order.start_addr = item.address
                this.order.point_id = item.id
                this.order.point_tid = item.tid
                this.orderPointAddress = item.address
                // console.log('this.order.start_addr', this.order.start_addr)

                if (this.order.coordinates) {
                    const coordinates = JSON.parse(this.order.coordinates)
                    if (coordinates.length === 2) {
                        this.priceUpdate({
                            lng: coordinates[0],
                            lat: coordinates[1],
                        })
                    }
                }
            },

            onClickOrderDescrSelect() {
                this.orderStep = 7
                this.orderStepLast = true
                this.orderDescrSelect = true
            },

            orderDescrEmpty() {
                return (!this.order.descr || this.order.descr == '') && this.orderDescrSelect
            },

            orderPayCostNext() {
                if (this.orderPayCostValid()) {
                    this.dialogPayCost = false
                }
            },

            orderValid() {
                return this.orderStepLast && this.orderPayCostValid() && this.orderDescrValid()
            },

            orderPayCostValid() {
                return this.order.pay_cost && this.order.pay_cost >= 100 && this.order.pay_cost < 100000
            },

            orderDescrValid() {
                // this.order.descr != undefined
                // const res = this.order.descr && this.order.descr.length > 0
                // console.log('orderDescrValid', res, this.order)
                return true
            },

            onInputPayCost(value) {
                console.log('onInputPayCost', value)
                if (!value) value = 0
                this.order.pay_cost = parseInt(value)
            },

            orderPayCost(c) {
                return this.orderPayCostValid() ? `<b>${this.order.pay}</b>, сумма заказа: <b>${+this.order.pay_cost} ₽</b>` : ''
            },

            orderDescr() {
                return this.order.descr && this.order.descr.length ? `<b>${this.order.descr.length > 50 ? this.order.descr.substr(0, 50) + '...' : this.order.descr}</b>` : ''
            },

            onClickOrderPay(pay) {
                this.order.pay = pay
                this.dialogPayCost = true
            },

            preorderNext() {
                if (this.preorderTimeReady != '') {
                    this.preorderStep += 1
                }
            },

            preorderDaysGenerate() {
                this.preorderDays = []

                const daysMax = 14
                const daysFixed = 6
                let holidaysCount = 0

                const holidayString = (holiday, dateCurrent) => {
                    return `${holiday.icon} ${dateCurrent.format('D MMM')}`
                }

                for (let i = 0; i < daysMax; i += 1) {
                    const dateCurrent = moment()
                        .set({
                            h: 0,
                            m: 0,
                            s: 0,
                            ms: 0,
                        })
                        .add(i + 1, 'days')

                    let text = dateCurrent.format('D MMM')

                    for (let j = 0; j < this.holidays.length; j += 1) {
                        const holiday = this.holidays[j]
                        const dateEnd = moment(holiday.date, 'D MMM')
                        const dateStart = moment(dateEnd).subtract(holiday.daysCount - 1, 'days')

                        if (dateCurrent.isSameOrAfter(dateStart) && dateCurrent.isSameOrBefore(dateEnd)) {
                            text = holidayString(holiday, dateCurrent)

                            if (i >= daysFixed) {
                                this.preorderDays.push(text)
                                holidaysCount += 1
                            }

                            if (i === daysMax - 1) {
                                for (let k = 0; k < holiday.daysCount - holidaysCount; k += 1) {
                                    this.preorderDays.push(holidayString(holiday, moment(dateCurrent).add(k + 1, 'days')))
                                }
                            }
                        }
                    }

                    if (i < daysFixed) {
                        this.preorderDays.push(text)
                    }
                }
            },

            onClickPreorderTimeDelivery() {
                if (this.preorderTimeDeliveryDisable()) {
                    return
                }

                this.order.mode = '⏰ Предзаказ'
                this.order.date = moment()

                let dateDay

                if (this.preorderDay > 6) {
                    dateDay = this.getDayStr(this.preorderDay)
                    this.holidays.forEach(holiday => {
                        dateDay = dateDay.replace(`${holiday.icon} `, '')
                    })
                } else {
                    dateDay = moment().add(this.preorderDay, 'day').format('D MMM')
                }

                let dateReady = moment(dateDay + ' ' + this.preorderTimeReady, 'D MMM HH:mm')
                if (moment(dateReady, 'D MMM').month() < moment().month()) {
                    // решение проблемы с декабрем и заказом на январь
                    dateReady.add(1, 'y')
                }
                this.order.date_ready = dateReady
                if (this.preorderTimeDeliveryMode == 2) {
                    this.order.date_delivery = moment(dateDay + ' ' + this.preorderTimeDeliveryTo, 'D MMM HH:mm')
                    this.orderAddDescr = `указан диапазон: ${this.preorderTimeDeliveryFrom}-${this.preorderTimeDeliveryTo}`
                } else {
                    this.order.date_delivery = moment(dateDay + ' ' + this.preorderTimeDeliveryFrom, 'D MMM HH:mm')
                    this.orderAddDescr = null
                }
                this.dialogTypePreorder = false
            },

            preorderTimeDeliveryDisable() {
                if (this.preorderTimeDeliveryMode == 1 && !this.preorderTimeDeliveryFrom) {
                    return true
                }
                if (this.preorderTimeDeliveryMode == 2 && (!this.preorderTimeDeliveryFrom || !this.preorderTimeDeliveryTo)) {
                    return true
                }
                return false
            },

            onClickNearestTime(time) {
                console.log('onClickNearestTime', time)
                this.order.mode = '🔥 Ближайший'
                this.order.date = moment()
                const dateReady = moment()
                let sec = time * 60

                if (sec < this.timeTake) {
                    sec = this.timeTake
                    const timeString = time ? time + ' мин' : 'уже готов'
                    this.orderAddDescr = `готовность: ${timeString}`
                }
                dateReady.add(sec, 's')
                this.order.date_ready = dateReady.utc().format()
                const dateDelivery = moment(dateReady)
                // TODO Понять почему тут нужно добавить 1 сек, чтобы время нормально показывало. Поправить.
                dateDelivery.add(this.timeDelivery, 's').add(1, 's')
                this.order.date_delivery = dateDelivery.utc().format()
                this.dialogTypeNearest = false
            },

            onClickPreorderDay(day) {
                this.order.mode = null
                this.preorderDay = day
                this.preorderStep = 2
            },

            onClickTypePreorder() {},

            promptCloseYes() {
                this.orderDialog = false
                this.$emit('close')
            },

            onMapClick() {
                this.showAddress = true
            },

            onAddressInput(value) {
                console.log('onAddressInput', value)
            },

            mapDefaultPosition() {
                // [lng, lat]
                let position
                if (this.settings.city.includes('Екатеринбург')) {
                    position = [60.60825, 56.839104]
                } else if (this.settings.city.includes('Хабаровск')) {
                    position = [135.084641, 48.482369]
                } else if (this.settings.city.includes('Новосибирск')) {
                    position = [82.955527, 55.026533]
                } else if (this.settings.city.includes('Ижевск')) {
                    position = [53.206896, 56.852677]
                } else if (this.settings.city.includes('Москва')) {
                    position = [37.617700, 55.755863]
                } else if (this.settings.city.includes('Благовещенск')) {
                    position = [127.533373, 50.2876389]
                } else if (this.settings.city.includes('Краснодар')) {
                    position = [38.985679, 45.066115]
                } else if (this.settings.city.includes('Находка')) {
                    position = [132.807147, 42.828675]
                } else if (this.settings.city.includes('Уссурийск')) {
                    position = [132.002090, 43.792126]
                } else if (this.settings.city.includes('Южно-Сахалинск')) {
                    position = [142.743736, 46.963269]
                } else if (this.settings.city.includes('Череповец')) {
                    position = [37.916389, 59.128696]
                } else {
                    // Владивосток
                    position = [131.885891, 43.114894]
                }
                return position
            },

            mapCreate(center) {
                console.log('mapCreate', center, this.addressMap)
                if (!this.addressMap) {
                    this.mapZoom = 17
                    if (center && center.length == 2) {
                        // this.mapPlaceFound = true
                    } else {
                        // this.mapPlaceFound = false
                        center = this.mapDefaultPosition()
                        this.mapZoom = 14
                    }
                    center = {
                        lat: +center[1],
                        lng: +center[0],
                    }
                    console.log('center after', center)
                    this.addressData.position = center
                    console.log('step 1', {
                        center,
                        zoom: this.mapZoom,
                        dragging: false,
                        scrollWheelZoom: false,
                        zoomControl: false,
                        doubleClickZoom: false,
                        attributionControl: false,
                    })
                    console.log('step 2')

                    this.addressMap = new L.map('address-map', {
                        center,
                        zoom: this.mapZoom,
                        dragging: false,
                        scrollWheelZoom: false,
                        zoomControl: false,
                        doubleClickZoom: false,
                        attributionControl: false,
                    })

                    console.log('step 3')
                    L.tileLayer(config.map.url, {
                        attributionControl: false,
                        maxZoom: 20,
                        minZoom: 13,
                        retina: '@2x',
                        detectRetina: true,
                    }).addTo(this.addressMap)
                } else {
                    this.mapUpdate(center)
                }
            },

            mapUpdate(position) {
                console.log('mapUpdate', position)
                this.mapZoom = 17
                if (position && position.length == 2) {
                    // this.mapPlaceFound = true
                } else {
                    // this.mapPlaceFound = false
                    position = this.mapDefaultPosition()
                    this.mapZoom = 14
                }
                position = {
                    lat: +position[1],
                    lng: +position[0],
                }
                this.addressData.position = position
                this.addressMap.setView(position, this.mapZoom)
                // setTimeout(() => this.mapInvalidateSize(), 5000)
            },

            mapInvalidateSize(s) {
                if (this.addressMap) this.addressMap.invalidateSize(false)
            },

            priceUpdate(position) {
                console.log('priceUpdate', position)

                const point_id = this.order.point_id
                const params = { point_id }

                if (position) {
                    params.lat = position.lat
                    params.lng = position.lng
                } else {
                    params.address = this.order.finish_addr
                }

                this.loadingOrderDialog = true

                this.$store
                    .dispatch('point_address', params)
                    .then(res => {
                        if (res.data.success) {
                            console.log('res.data', res.data)
                            this.order.fee_point = +res.data.price.point
                            if (this.courierPrice > 0) {
                                this.order.fee_courier = this.courierPrice
                            } else {
                                this.order.fee_courier = +res.data.price.courier
                            }
                            this.pointPrice = this.order.fee_point

                            if (res.data.times) {
                                this.timeTake = res.data.times.take * 60
                                this.timeDelivery = res.data.times.delivery * 60
                            }

                            if (!this.order.options) {
                                this.order.options = {}
                            }

                            this.order.options.distance = res.data.distance
                        } else {
                            console.log('point_address fail')
                            this.notifyToast('Не удалось определить стоимость доставки', { color: 'error', timeout: 4000 })
                        }

                        this.loadingOrderDialog = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.notifyToast(`Ошибка определения стоимости доставки - ${err.message}`, { color: 'error', timeout: 4000 })

                        this.loadingOrderDialog = false
                    })
            },

            onClickCreate() {
                if (this.orderValid()) {
                    this.orderDialog = false
                    if (!this.order.descr) {
                        this.order.descr = ''
                    }
                    if (this.orderAddDescr) {
                        if (this.order.descr === '') {
                            this.order.descr = this.orderAddDescr
                        } else {
                            this.order.descr = this.order.descr.replace(this.orderAddDescr, '')
                            this.order.descr = `${this.orderAddDescr}, ${this.order.descr}`
                        }
                    }
                    this.order.finish_addr = this.getAddress()
                    this.order.date_take = null
                    this.order.date_complete = null
                    this.$emit('create', this.order)
                    this.$emit('close')
                }
            },

            onClickClose() {
                if (!this.promptCloseFlag) {
                    this.orderDialog = false
                    this.$emit('close')
                }
                this.promptClose = true
            },

            distanceString(d) {
                if (!d) d = 0
                // console.log('distance', d)
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1) + ' км'
                }
                return '--'
            },

            distanceNumber(d) {
                if (!d) d = 0
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1)
                }
                return 0
            },

            getAddress() {
                let res = ''
                if (this.order.finish_addr) {
                    res = this.order.finish_addr.replace(',', '')
                    if (this.addressAdditions.flat) {
                        res += ', кв. ' + this.addressAdditions.flat
                    }
                    if (this.addressAdditions.entrance) {
                        res += ', п. ' + this.addressAdditions.entrance
                    }
                    if (this.addressAdditions.floor) {
                        res += ', эт. ' + this.addressAdditions.floor
                    }
                    if (this.addressAdditions.other) {
                        res += ', ' + this.addressAdditions.other
                    }
                }
                return res
            },

            getTimes() {
                let res = ''
                if (this.order.mode) {
                    res = `<b>${this.order.mode}</b>: `
                    res += `забрать <b>до ${this.timeToString(this.order.date_ready, false)}</b>`
                    res += `, доставить <b>к ${this.timeToString(this.order.date_delivery, false)}</b>`
                }
                return res
            },

            getDayStr(day) {
                let res = ''
                if (day > 0) {
                    res = this.preorderDays[day - 1]
                } else {
                    res = '✅ Сегодня'
                }
                return res
            },
        },
    }
</script>

<style>
    .input-point .v-input__slot,
    .input-point input {
        cursor: pointer !important;
    }

    .input-cost {
        width: 100px;
    }

    .input-cost .mdi-currency-rub {
        font-size: 18px !important;
    }

    /* .input-cost .v-input__prepend-outer {
        margin-right: 0 !important;
    } */
    .v-stepper__content {
        padding: 4px 60px 10px 23px !important;
    }

    .v-stepper--vertical {
        padding-bottom: 8px;
    }

    .v-stepper--vertical .v-stepper__step {
        padding: 12px 24px 6px;
    }

    .v-stepper--vertical .v-stepper__content {
        margin: -4px -36px -10px 36px !important;
    }

    .v-stepper__step--active .v-stepper__label {
        text-shadow: none !important;
        font-weight: 600;
    }

    .v-stepper__label {
        display: flex;
        flex-direction: column;
    }

    .v-stepper__step--editable:hover .v-stepper__label {
        text-shadow: none !important;
    }

    .v-stepper__wrapper {
        background: rgba(0, 0, 0, 0.03);
    }
</style>

<style scoped>
    .keyboard {
        text-transform: none;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        padding: 0 10px !important;
        height: 40px !important;
    }

    .stepper-content {
        margin: 0 !important;
    }

    .step-info {
        line-height: 1.2rem;
        padding-top: 5px !important;
        color: #002b91;
        font-weight: 300;
        min-height: 24px;
    }

    .step-empty {
        line-height: 1.2rem;
        padding-top: 5px !important;
        color: #636363;
        font-weight: 300;
        font-style: italic;
    }

    .order-create-dialog {
        height: 100%;
        width: 100%;
        /* padding-top: 10px !important; */
    }

    .btn-update {
        height: 24px !important;
        min-width: 168px !important;
    }

    .map-pin {
        width: 30px !important;
        height: 30px !important;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 30px;
        right: 0;
        z-index: 401;
    }

    #address-map {
        height: 190px;
        margin: 0 auto;
        box-shadow: 0px 0px 2px #aaa, 1px 1px 2px #aaa;
    }

    #address-map.clickable {
        cursor: pointer;
    }

    #address-map.clickable:hover {
        box-shadow: 0px 0px 2px grey, 1px 1px 4px grey;
    }
</style>
