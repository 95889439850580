<template>
    <div class="orders">
        
        <template>
            <v-container class="pa-0">
                <v-snackbar right top :value="snackbarText" :timeout="6000" v-model="snackbar" color="info">
                    <v-progress-circular color="deep-orange lighten-2"
                        :value="Math.floor(100 * (currentTime / snackbarTime))" class="mx-2">
                        <strong>{{ snackbarLoad }}</strong>
                    </v-progress-circular>

                    <span class="mx-2 bold">
                        <b>{{ snackbarText }}</b>
                    </span>

                    <v-btn color="deep-orange " @click="snackRestoreOrder"> <span color="black">Не удалять</span>
                    </v-btn>
                </v-snackbar>
            </v-container>
        </template>

        <v-row style="max-width: 2400px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto elevation-6">
                    <v-toolbar height="50" class="d-flex overflow-x-auto" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            Всего заказов: {{ orders.length + multiOrders.length }}, доставлено: {{ ordersComplete }}, {{ userIsPoint ?
                            'расходы' : 'выручка' }}: {{ ordersFeePoint }} ₽{{ userIsPoint ? '' : ', зарплата: ' +
                            ordersFeeCourier + ' ₽' }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn-toggle v-model="calendar" tile group mandatory dense>
                            <v-btn value="left" @click="onClickToday">
                                <v-icon>mdi-calendar</v-icon> {{ $t('orders.buttons.today') }}
                            </v-btn>
                            <v-btn value="center" @click="onClickYesterday">
                                <v-icon>mdi-calendar-arrow-left</v-icon> {{ $t('orders.buttons.yesterday') }}
                            </v-btn>
                            <v-btn value="right" @click="onClickMonth">
                                <v-icon>mdi-calendar-month</v-icon> {{ $t('orders.buttons.month') }}
                            </v-btn>
                            <v-menu v-model="menu_calendar" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-btn text class="menu-button" v-on="on">
                                        <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                                        {{ $t('orders.buttons.range') }}
                                    </v-btn>
                                </template>
                                <div @keydown.esc="closeCalendar" tabindex="0">
                                    <v-date-picker range v-model="dates" locale="ru-ru"
                                        selected-items-text="Выбран период" :first-day-of-week="1"
                                        @change="onClickChangeDate"> </v-date-picker>
                                </div>
                            </v-menu>
                        </v-btn-toggle>

                        <v-spacer></v-spacer>

                        <!-- Удаленные заказы (можно найти через поиск "Удаленные заказы") -->

                        <!-- вызывается попап если корзина активна -->
                        <v-dialog scrollable v-model="popupDeletedOrder">
                            <template v-slot:activator="{ on, attrs }">
                                <!-- корзина вызывающая попап -->
                                <v-btn v-bind="attrs" v-on="on" :disabled="deletedOrdersCount === 0" icon>
                                    <!-- v-badge :content - показывает значения :value - отображает значок-->
                                    <v-badge overlap :content="deletedOrdersCount" :value="deletedOrdersCount"
                                        color="red">
                                        <v-icon large>mdi-trash-can-outline</v-icon>
                                    </v-badge>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="d-flex justify-space-between text-h5 grey lighten-2">
                                    Удаленные заказы
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" color="primary" text @click="popupDeletedOrder = false">
                                            Закрыть</v-btn>
                                    </v-card-actions>
                                </v-card-title>
                                <template>
                                    <v-card>
                                        <tableDeleted :data="{ deleteData: deletedOrdersData, orderData: orders }"
                                            @successRestore="restoreStatus"></tableDeleted>
                                    </v-card>
                                </template>
                            </v-card>
                        </v-dialog>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickDownloadData" class="mx-4 px-2" tile icon rounded>
                                        <v-img src="../../assets/images/excel-300.png" width="40"></v-img>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузить заказы в таблицу</span>
                        </v-tooltip>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-row dense justify="start">

                            <v-col cols="12" sm="6" md="2">
                                <v-select v-if="!userIsPoint" class="mx-2 flex-grow-0" style="width: 300px"
                                    no-data-text="Нет данных" @change="updateData" :items="filter_points" name="point"
                                    item-text="name" item-value="id" v-model="filter_point" label="По точке"
                                    hide-details></v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="2">
                                <v-select class="mx-2 flex-grow-0" style="width: 300px" no-data-text="Нет данных"
                                    @change="updateData" :items="filter_couriers" name="courier" item-text="name"
                                    item-value="tid" v-model="filter_courier" label="По курьеру" hide-details>
                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="6" md="2">
                                <v-select class="mx-2 flex-grow-0" style="width: 300px" no-data-text="Нет данных"
                                    @change="updateData" :items="filter_order_modes" name="order_mode"
                                    v-model="filter_order_mode" label="По типу" hide-details></v-select>
                            </v-col>

                            <v-spacer></v-spacer>

                            <v-col cols="12" sm="6" md="2">
                                <v-btn v-if="!userIsPoint" outlined small tile color="primary"
                                    class="mx-2 mt-3 flex-grow-0" style="height: 35px" @click="clickFeeUpdate()">
                                    Обновить цены<br />на доставку</v-btn>
                            </v-col>

                            <v-spacer></v-spacer>

                            <v-col cols="12" sm="6" md="2">
                                <v-text-field style="width: 300px; flex: initial" v-model="search"
                                    append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12">

                                <v-expansion-panels accordion flat>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>

                                            <p class="mb-0">
                                                <b>Среднее время:</b> забора <b>{{ timeMinutesHuman(averageTimeTake) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeTake">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(averageTimeTake - $store.state.settings.timeTake)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(averageTimeTake -
                                                            $store.state.settings.timeTake, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение среднего от времени забора
                                                        ({{$store.state.settings.timeTake}} мин.)</span>
                                                </v-tooltip>

                                                ; доставки <b>{{ timeMinutesHuman(averageTimeDelivery) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeDelivery">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(averageTimeDelivery - $store.state.settings.timeDelivery)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(averageTimeDelivery -
                                                            $store.state.settings.timeDelivery, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение среднего от времени доставки
                                                        ({{$store.state.settings.timeDelivery}} мин.)</span>
                                                </v-tooltip>

                                            </p>

                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="mb-0">

                                            <p class="mb-0">
                                                <b>Миним. время:</b> забора <b>{{ timeMinutesHuman(minimumTimeTake) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeTake">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(minimumTimeTake - $store.state.settings.timeTake)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(minimumTimeTake -
                                                            $store.state.settings.timeTake, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение от времени забора
                                                        ({{$store.state.settings.timeTake}} мин.)</span>
                                                </v-tooltip>

                                                ; доставки <b>{{ timeMinutesHuman(minimumTimeDelivery) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeDelivery">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(minimumTimeDelivery - $store.state.settings.timeDelivery)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(minimumTimeDelivery -
                                                            $store.state.settings.timeDelivery, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение от времени доставки
                                                        ({{$store.state.settings.timeDelivery}} мин.)</span>
                                                </v-tooltip>

                                            </p>


                                            <p class="mb-0">
                                                <b>Макс. время:</b> забора <b>{{ timeMinutesHuman(maximumTimeTake) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeTake">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(maximumTimeTake - $store.state.settings.timeTake)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(maximumTimeTake -
                                                            $store.state.settings.timeTake, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение от времени забора
                                                        ({{$store.state.settings.timeTake}} мин.)</span>
                                                </v-tooltip>

                                                ; доставки <b>{{ timeMinutesHuman(maximumTimeDelivery) }}</b>

                                                <v-tooltip top
                                                    v-if="!$store.state.settings.timesByDistance && $store.state.settings.timeDelivery">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs" v-on="on" class="px-1 mb-1"
                                                            :color="timeMinutesColorHtml(maximumTimeDelivery - $store.state.settings.timeDelivery)"
                                                            x-small label text-color="white">
                                                            {{ timeMinutesHuman(maximumTimeDelivery -
                                                            $store.state.settings.timeDelivery, true) }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Отклонение от времени доставки
                                                        ({{$store.state.settings.timeDelivery}} мин.)</span>
                                                </v-tooltip>

                                            </p>

                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-data-table :mobile-breakpoint="0" dense no-data-text="Нет данных" must-sort
                        no-results-text="Нет данных, попробуйте изменить запрос" :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100, -1],
                        }" :headers="headers" :search="search" :items="getAllTypeOfOrders" :item-class="rowItemClass"
                        item-key="index" :loading="loading" :loading-text="$t('common.loading')"
                        :sort-by="'date_delivery'"
                        :sort-desc="true"
                        @click:row="onClickOrderEdit($event)">
                        <template v-slot:[`item.options.marketingSource`]="{ item }">
                            <span>{{ item.options?.marketingSource ? marketingSource(item.options.marketingSource) : '' }}</span>
                        </template>

                        <template v-slot:[`item.name`]="{ item }">
                            <b>{{ item.name ? item.name : getMultiOrderName(item) }}</b>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <div class="d-flex">
                                <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-bind="attrs" v-on="on" :color="orderStatusColorHtml(item.status)"
                                            text-color="white">
                                            <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}
                                            </v-icon>
                                            {{ orderStatusStr2(item) }}
                                        </v-chip>
                                    </template>

                                    <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                        <div class="order-card" v-html="orderContent(item)"></div>
                                    </v-card>
                                </v-menu>
                                <v-btn class="mt-1"
                                    v-if="!userIsPoint && item.status != config.order.status.complete && (item.status != config.order.status.new || item.courier_tid)"
                                    outlined text tile x-small @click="onClickOrderStatus(item)">
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <template v-slot:[`item.guest`]="{ item }">
                            <span style="min-width: 180px">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td v-bind="attrs" v-on="on">
                                            <router-link v-if="item.guest_phone"
                                                :to="{ name: 'guests_phone', params: { phone: item.guest_phone } }"
                                                class="text-decoration-none" style="color: inherit">
                                                <span class="py-2 tooltip-text"
                                                    :class="loading || item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'"
                                                    @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>
                                            </router-link>

                                            <span v-else class="py-2 tooltip-text">{{ getGuestStr(item.guest) }}</span>
                                        </td>
                                    </template>
                                    <span>{{ getGuestStr(item.guest) }}</span>
                                </v-tooltip>
                                <v-btn class="ml-2" v-if="!item.guest_id && item.status != config.order.status.forming"
                                    outlined tile text x-small @click="onClickGuestCreate(item)">
                                    <v-icon small>mdi-account-plus-outline</v-icon>
                                </v-btn>
                            </span>
                        </template>

                        <template v-slot:[`item.finish_addr`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="tooltip-text" v-bind="attrs" v-on="on">
                                        <span
                                            :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">{{
                                            item.finish_addr.substr(0, 24) }}</span>
                                    </td>
                                </template>
                                <span>{{ item.finish_addr }}</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.fee_courier`]="{ item }">
                            <span
                                :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">
                                {{ +item.fee_courier }}
                            </span>
                        </template>

                        <template v-slot:[`item.fee_point`]="{ item }">
                            <span
                                :style="item.fee_point == 0 || item.fee_point == $store.state.settings.price_point_default ? 'color: red' : ''">
                                {{ +item.fee_point }}
                            </span>
                        </template>

                        <template v-slot:[`item.options.distance`]="{ item }">
                            <span>
                                {{ distance(item.options ? item.options.distance : null) }}
                            </span>
                        </template>

                        <template v-slot:[`item.courier_name`]="{ item }">
                            <v-avatar size="24" class="mr-2" v-if="item.courier_name != '--'">
                                <img alt="courier"
                                    :src="`${config.api.delivery + 'user_avatar?tid=' + item.courier_tid}`" />
                            </v-avatar>
                            <b v-if="userIsPoint">{{ item.courier_name.split(' ')[0] }}</b>
                            <b v-else>{{ item.courier_name.length > 20 ? item.courier_name.substr(0, 20) + '...' :
                            item.courier_name }}</b>
                        </template>

                        <template v-slot:[`item.date`]="{ item }">
                            {{ item.mode.split(' ')[0] }} 
                            {{ timeToString(item.date) }}
                        </template>

                        <template v-slot:[`item.date_ready`]="{ item }">
                            {{ timeToString(item.date_ready) }}
                        </template>

                        <template v-slot:[`item.date_delivery`]="{ item }">
                            {{ timeToString(item.date_delivery) }}
                        </template>

                        <template v-slot:[`item.date_take`]="{ item }">
                            <span class="mr-2">{{ timeToString(item.date_take) }}</span>
                            <v-chip v-if="item.date_take" class="px-1 mb-1"
                                :color="timeColorHtml(item.date_take, item.date_ready)" x-small label
                                text-color="white">
                                {{ timeDiff(item.date_take, item.date_ready) }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.date_complete`]="{ item }">
                            <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                            <v-chip v-if="item.date_complete" class="px-1 mb-1"
                                :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label
                                text-color="white">
                                {{ timeDiff(item.date_complete, item.date_delivery) }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.source`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="tooltip-text" v-bind="attrs" v-on="on">
                                        <v-avatar size="24">
                                            <img alt="source" :src="iconSource(item)" />
                                        </v-avatar>
                                    </td>
                                </template>
                                <span>{{ tooltipSource(item) }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <dialog-order-edit
            v-if="dialogEdit"
            :data="currentItem"
            :header="dialogHeader"
            :user-operator="$store.state.user.role === config.user.role.operator"
            :settings="$store.state.settings"
            :courierTariff="courierTariff"
            :couriersList="couriers_list.filter(courier => courier.tid !== 'null')"
            :deleteDisable="disBtn"
            @save="onOrderSave"
            @delete="onOrderDelete"
            @copy="onOrderCopy"
            @close="dialogEdit = false"
            @darall-send="onDarallSend"
        />

        <dialog-order-create
            v-if="dialogCreate"
            :copyData="copyOrder"
            :header="dialogHeader"
            :user-operator="$store.state.user.role === config.user.role.operator"
            :settings="$store.state.settings"
            :courierPrice="courierTariff"
            :points="getPointsFiltered()"
            @create="onOrderCreate"
            @close="dialogCreate = false"
        />

        <v-dialog v-model="dialogStatus" width="unset">
            <v-card>
                <v-card-title class="grey lighten-2">
                    {{ dialogHeader }}
                </v-card-title>

                <v-card-text>
                    <div class="d-flex px-4 py-5 justify-md-space-around">
                        <v-btn class="ma-2"
                            v-if="currentItem.status == config.order.status.way_guest || currentItem.status == config.order.status.way_point || currentItem.status == config.order.status.passed"
                            :color="orderStatusColorHtml(config.order.status.new)" dark @click="onClickOrderNew()">Новый
                        </v-btn>

                        <v-btn class="ma-2"
                            v-if="currentItem.status == config.order.status.way_point || currentItem.status == config.order.status.way_guest || currentItem.status == config.order.status.passed || currentItem.courier_tid"
                            :color="orderStatusColorHtml(config.order.status.complete)" dark
                            @click="onClickOrderComplete()">Доставлен</v-btn>

                        <v-btn class="ma-2" v-if="currentItem.status == config.order.status.forming" color="error" dark
                            @click="onClickOrderDelete()">Удалить</v-btn>
                    </div>
                    <v-divider></v-divider>
                    <span v-html="dialogContent"></span>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptDarallSend" width="500">
            <v-card :loading="loadingDarallSend">
                <v-card-title class="grey lighten-2"> Отправка заказа </v-card-title>

                <v-card-text class="py-4">
                    <span>Вы уверены, что хотите <b>отправить заказ {{ currentItem.name }} оператору Darall</b>?</span>
                    <br /><br />
                    <span>Стоимость доставки: <b>{{
                        loadingDarallSend ?
                        'подождите, идет загрузка...' :
                        (darallPricePoint ? `${darallPricePoint} ₽` : 'не определена, будет задана оператором Darall')
                    }}</b></span><br />
                    <span>Когда забрать: <b>{{
                        loadingDarallSend ?
                        '--' :
                        (darallDateReady ? `${timeToString(darallDateReady)}` : 'не определено, будет задано оператором Darall')
                    }}</b></span><br />
                    <span>Когда доставить: <b>{{
                        loadingDarallSend ?
                        '--' :
                        (darallDateDelivery ? `${timeToString(darallDateDelivery)}` : 'не определено, будет задано оператором Darall')
                    }}</b></span><br />
                    <br />
                    <span>Времена забора и доставки заказа, а также стоимость могут быть пересчитаны <b>оператором Darall</b>.</span>
                    <br /><br />
                    <span>После отправки заказ станет недоступен в этом кабинете, но будет доступен <b>в личном кабинете Darall</b> и в <a href="https://t.me/DarallBot">Телеграм боте Darall</a>.</span>
                    <br /><br />
                    <span class="red--text">Внимание: отправленный заказ нельзя будет вернуть!</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="loadingDarallSend" class="mx-2" color="success" dark @click="promptDarallSendYes">Отправить</v-btn>
                    <v-btn class="mx-2" color="error" text @click="promptDarallSend = false">Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptFeeUpdate" width="500">
            <v-card>
                <v-card-title class="grey lighten-2"> Подтверждение действия </v-card-title>

                <v-card-text class="py-4">
                    <span>Вы уверены, что хотите <b>обновить цены на доставку для всех выбранных заказов</b>?</span>
                    <br />
                    <span class="red--text">Внимание: результат нельзя будет вернуть назад!</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" dark @click="promptFeeUpdateYes">Обновить</v-btn>
                    <v-btn class="mx-2" color="error" text @click="promptFeeUpdate = false">Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- TODO Временно отключена возможность точкам супры создавать заказы, так как курьеры используют кабинет -->

        <v-tooltip top open-delay="200">
            <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="loading || ($store.state.userIsPoint && $store.state.user.point.name.includes('Супра'))" color="purple" v-bind="attrs" v-on="on" fab :dark="!loading" class="btn-create"
                    @click="onClickOrderCreate">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Создать новый заказ</span>
        </v-tooltip>

        <span class="pb-12 d-block" />
        <span class="pb-12 d-block" />

    </div>
</template>

<script>
import config from '../../config'
import moment from 'moment-timezone'

moment.locale('ru')

import DateTime from '../../mixins/DateTime.js'
import Status from '../../mixins/Status.js'
import Orders from '../../mixins/Orders.js'
import Notify from '../../mixins/Notify.js'
import tableDeleted from './tableDeleted.vue'
import dialogOrderEdit from './DialogOrderEdit.vue'
import dialogOrderCreate from './DialogOrderCreate.vue'

export default {
    components: {
        tableDeleted,
        dialogOrderEdit,
        dialogOrderCreate,
    },
    mixins: [DateTime, Status, Orders, Notify],
    data() {
        return {

            // snackbar случайно удаленных заказов
            snackbar: false,
            snackbarText: '',
            snackbarTime: 5000,
            currentTime: 0,
            // отключение кнопки удаления (был баг что в если во время snackbar открыть другой заказ и пытаться непрерывно кликать по кнопке удалить (которая в момент удаления disabled), то в момент когда пропадает snackbar и кнопка активируется можно успеть передать параметр закликанного заказа в currentDeleteItem и досрочно отправить этот заказ в нотифай предыдущего удаления. По факту удалился предыдущий заказ а в нотифай полетел закликанный.
            disBtn: false,
            // сбрасывает interval
            snackbarCancelInterval: false,
            // фиксирует удаленный заказ для snackbar (был баг что в процессе удаления, когда висит snackbar, при нажатии на другие заказы он ловил другие заказы и отправлял их в нотифай как удаленные)
            currentDeleteItem: {},

            // переключатель попап удаленных заказов
            popupDeletedOrder: false,
            // данные по удаленным заказам
            deletedOrdersData: [],


            courierTariff: 0,
            dates: [],
            calendar: 0,
            menu_calendar: false,
            loading: false,
            search: '',
            searchDeleted: '',
            ordersComplete: '--',
            ordersFeePoint: '--',
            ordersFeeCourier: '--',
            orders: [],
            points: [],
            couriers: [],
            filter_point: 0,
            filter_points: [],
            filter_courier: 0,
            filter_couriers: [],
            couriers_list: [],
            filter_order_mode: 0,
            filter_order_modes: [
                {
                    text: '< Все >',
                    value: 0,
                },
                {
                    text: '⏰ Предзаказ',
                    value: 1,
                },
                {
                    text: '🔥 Ближайший',
                    value: 2,
                },
            ],
            headers: [],
            orderStatuses: [],
            timer: null,
            dialogStatus: false,
            dialogEdit: false,
            dialogCreate: false,
            promptDarallSend: false,
            dialogHeader: '',
            dialogContent: '',
            config,
            currentItem: {},
            copyOrder: null,
            changeFlag: false,
            promptFeeUpdate: false,
            d1: null,
            d2: null,
            userIsPoint: false,
            guestNameClick: false,
            guestCreateClick: false,
            averageTimeTake: 0,
            averageTimeDelivery: 0,
            minimumTimeTake: 0,
            minimumTimeDelivery: 0,
            maximumTimeTake: 0,
            maximumTimeDelivery: 0,
            timerId: null,
            orderPointDarall: 0,
            darallPriceCourier: 0,
            darallPricePoint: 0,
            darallTimeReady: null,
            darallTimeDelivery: null,
            darallDateReady: null,
            darallDateDelivery: null,
            loadingDarallSend: false,
            multiOrders: [],
            multiOrderPointsDarall: [],
        }
    },
    created() {
        this.userIsPoint = this.$store.state.user.role != config.user.role.operator
        if (!this.userIsPoint) {
            this.headers = [
                {
                    text: 'Сист.',
                    value: 'source',
                    sortable: false,
                },
                {
                    text: 'Название',
                    value: 'name',
                },
                {
                    text: 'Статус',
                    value: 'status',
                },
                {
                    text: 'Гость',
                    sortable: false,
                    value: 'guest',
                },
                {
                    text: 'Адрес доставки',
                    sortable: false,
                    value: 'finish_addr',
                },
                {
                    text: 'Цена курьера',
                    value: 'fee_courier',
                },
                {
                    text: 'Цена точки',
                    value: 'fee_point',
                },
                {
                    text: 'Расстояние',
                    value: 'options.distance',
                },
                {
                    text: 'Курьер',
                    value: 'courier_name',
                },
                {
                    text: 'Готов',
                    value: 'date',
                },
                {
                    text: 'Забрать',
                    value: 'date_ready',
                },
                {
                    text: 'Доставить',
                    value: 'date_delivery',
                },
                {
                    text: 'Забран',
                    value: 'date_take',
                },
                {
                    text: 'Доставлен',
                    value: 'date_complete',
                },
            ]
        } else {
            this.headers = [
                {
                    text: 'Сист.',
                    value: 'source',
                    sortable: false,
                },
                {
                    text: 'Название',
                    value: 'name',
                },
                {
                    text: 'Статус',
                    value: 'status',
                },
                {
                    text: 'Гость',
                    sortable: false,
                    value: 'guest',
                },
                {
                    text: 'Адрес доставки',
                    sortable: false,
                    value: 'finish_addr',
                },
                {
                    text: 'Цена',
                    value: 'fee_point',
                },
                {
                    text: 'Расстояние',
                    value: 'options.distance',
                },
                {
                    text: 'Курьер',
                    value: 'courier_name',
                },
                {
                    text: 'Готов',
                    value: 'date',
                },
                {
                    text: 'Забрать',
                    value: 'date_ready',
                },
                {
                    text: 'Доставить',
                    value: 'date_delivery',
                },
                {
                    text: 'Забран',
                    value: 'date_take',
                },
                {
                    text: 'Доставлен',
                    value: 'date_complete',
                },
            ]
        }
        if (this.$store.state.settings.marketingSourceView) {
            this.headers.splice(1, 0, {
                text: 'Источник',
                value: 'options.marketingSource',
            })
        }
    },
    mounted() {
        console.log('orders mount')
        this.dates[0] = moment().format('YYYY-MM-DD')
        this.dates[1] = moment().format('YYYY-MM-DD')
        this.d1 = this.getDate1String()
        this.d2 = this.getDate2String()
        this.updateData()
    },

    beforeDestroy() { },

    destroyed() {
        return this.timer ? clearInterval(this.timer) : false
    },
    watch: {},
    computed: {
        getAllTypeOfOrders() {
            return [...this.orders, ...this.multiOrders]
        },
        // активирует/блокирует корзину и показывает число удаленных заказов на ней
        snackbarLoad() {
            let result = Math.floor(5 - (100 * (this.currentTime / this.snackbarTime)) / 20)
            if (result >= 0) {
                return result
            }
            return 0
        },

        deletedOrdersCount() {
            return this.deletedOrdersData.length
        },

    },
    methods: {
        getMultiOrderName(item) {
            return item.orders.reduce((acc, i) => {
                acc += `${i.name}, `
                return acc
            }, '').slice(0, -2)
        },

        getPointsFiltered() {
            let points
            if (this.$store.state.user.role === config.user.role.operator) {
                points = this.filter_points.filter(p => p.id > 0)
            } else {
                points = this.filter_points.filter(p => p.id == this.$store.state.user.point_id)
            }
            return points
        },

        // сработает после окончания таймера
        notifyDeleteOrder() {
            const order = this.currentDeleteItem
            const user = this.$store.state.user.name
            const userRole = this.$store.state.user.role
            console.log('user name = ', user)
            console.log('user role = ', userRole)
            const data = {
                order,
                user,
                userRole,
            }
            if (!order.isCombined) {
                this.$store
                .dispatch('order_delete_notify', data)
                .then(res => {
                    if (res.data.success) {
                        console.log('notify ok')
                    }
                })
                .catch(err => {
                    console.log('notify false', err.name, err.message)
                })
            } else {
                this.$store
                .dispatch('multi_order_delete_notify', data)
                .then(res => {
                    if (res.data.success) {
                        console.log('notify ok')
                    }
                })
                .catch(err => {
                    console.log('notify false', err.name, err.message)
                })
            }
        },

        // возвращает удаленный заказ обратно в список
        snackRestoreOrder() {
            this.snackbar = false
            this.snackbarCancelInterval = true

            const restoreOrder = this.currentDeleteItem
            const indexOrder = this.currentDeleteItem.indexOrder
            delete this.currentDeleteItem.indexOrder

            this.disBtn = false

            if (this.currentDeleteItem.isCombined) {
                return this.multiOrders.splice(indexOrder, 0, restoreOrder)
            } else {
                return this.orders.splice(indexOrder, 0, restoreOrder)
            }
        },

        // запускает таймер по восстановлению заказа. По окончании отправляет notify в телеграмм бот что заказ удален
        syncPbar() {
            console.log('start syncPbar')

            // останавливаем отправку notify если нажали кнопку восстановления
            if (this.snackbarCancelInterval) {
                this.snackbarCancelInterval = false
                if (this.timerId) {
                    clearTimeout(this.timerId)
                    this.timerId = null
                }
                return
            }

            this.timerId = setTimeout(() => {
                // Increment the time counter by 100
                this.currentTime += 1000
                this.syncPbar()
            }, 1000)

            if (this.currentTime > this.snackbarTime) {
                this.snackbar = false
            }
            if (this.currentTime > this.snackbarTime + 1200 && this.snackbarCancelInterval === false) {
                if (this.timerId) {
                    clearTimeout(this.timerId)
                    this.timerId = null
                }
                // Запускает удаление
                this.deleteOrder()
            }
        },

        // getDeletedOrders() {
        //     this.deletedOrdersData = []

        //     this.$store
        //         .dispatch('orders_deleted', {
        //             date1: this.d1,
        //             date2: this.d2,
        //             point_id: this.userIsPoint ? this.$store.state.user.point_id : this.filter_point,
        //             deliveryTime: true,
        //         })
        //         .then(res => {
        //             if (res.data.success) {
        //                 this.deletedOrdersData = res.data.orders.reverse()
        //             }
        //         })
        // },

        // получает все удаленные заказы за конкретную дату (так же активируется при срабатывании updateData() )
        getDeletedAllTypesOrders() {
            this.deletedOrdersData = []

            this.$store
                .dispatch('orders_deleted', {
                    date1: this.d1,
                    date2: this.d2,
                    point_id: this.userIsPoint ? this.$store.state.user.point_id : this.filter_point,
                    deliveryTime: true,
                })
                .then(res => {
                    if (res.data.success) {
                        this.deletedOrdersData.push(...res.data.orders.reverse())
                    }
                })

            this.$store
                .dispatch('multi_orders_deleted', {
                    date1: this.d1,
                    date2: this.d2,
                    point_id: this.userIsPoint ? this.$store.state.user.point_id : this.filter_point,
                    deliveryTime: true,
                })
                .then(res => {
                    if (res.data.success) {
                        this.deletedOrdersData.push(...res.data.multiOrders.reverse())
                    }
                })
        },

        // получает данные от ребенка (tableDeleted), закрывая попап и обновляя данные если true
        restoreStatus(data) {
            console.log('test restore data = ', data)
            if (data.status) {
                this.updateData()
                // this.popupDeletedOrder = false
                return this.restoreSuccess(data.name)
            } else {
                console.log('restore false')
                return this.restoreError(data.name)
            }
        },

        restoreSuccess(name) {
            console.log('test restoreSuccess = ', name)
            const text = `Заказ ${name} успешно восстановлен`
            this.notifyToast(text, { color: 'success', x: 'right', y: 'top', timeout: 4000 })
        },

        restoreError(name) {
            const text = `Ошибка восстановления заказа ${name}`
            this.notifyToast(text, { color: 'error', x: 'right', y: 'top', timeout: 4000 })
        },

        setTimer() {
            if (this.count > 0) {
                setTimeout(() => {
                    this.count -= 1
                    console.log('test count', this.count)
                    this.setTimer()
                }, 1000)
            }
        },


        onClickGuest(order) {
            if (order.guest_id) {
                this.guestNameClick = true
                const name = order.guest_name ? order.guest_name + ' ' : ''
                this.notifyToast('Гость: ' + name + order.guest_phone, { x: 'right', y: 'top', color: 'info', timeout: 4000 })
                setTimeout(() => {
                    this.guestNameClick = false
                }, 100)
            }
        },

        onClickGuestCreate(order) {
            this.guestCreateClick = true
            this.$store.state.loading = true

            this.$store
                .dispatch('guest_create', order)
                .then(res => {
                    if (res.data.success) {
                        const guest = res.data.guest
                        order.guest_id = guest.id
                        order.guest_name = guest.name
                        order.guest_phone = guest.phone
                        this.notifyToast('Успешно создан гость ' + guest.name, { color: 'success', timeout: 4000 })
                    } else {
                        this.notifyToast('Не удалось создать гостя, нет телефона', { color: 'error', timeout: 4000 })
                    }
                    this.guestCreateClick = false
                    this.$store.state.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.guestCreateClick = false
                    this.$store.state.loading = false
                    this.notifyToast('Не удалось создать гостя, нет телефона', { color: 'error', timeout: 4000 })
                })
        },

        getGuestPhoneName(order) {
            let guest_name = ''
            if (order.guest_id && order.guest_phone) {
                guest_name += order.guest_phone
                if (order.guest_name) guest_name += ' - ' + order.guest_name
            } else {
                if (order.guest) {
                    guest_name = this.getGuestStr(order.guest).substr(0, 24)
                }
            }
            return guest_name
        },

        // вызывает snackbar быстрой отмены удаления
        snackbarStart(index) {
            if (this.timerId) {
                clearTimeout(this.timerId)
                this.timerId = null
                this.deleteOrder()
            }

            //отключение кнопок удаления на время snackbar
            this.disBtn = true
            const orderId = this.currentDeleteItem.id
            // находим индекс заказа, что бы вставить его туда же откуда взяли
            const indexOrder = !this.currentDeleteItem.isCombined  ? this.orders.findIndex(order => order.id === orderId) : this.multiOrders.findIndex(multiOrder => multiOrder.id === orderId)
            console.log('index order?', this.currentDeleteItem)
            //добавляем в заказ на случай восстановления через snackbar
            this.currentDeleteItem.indexOrder = indexOrder
            //удаляем заказ из списка
            if (this.currentDeleteItem.isCombined) {
                this.multiOrders = this.multiOrders.filter(multiOrder => multiOrder.id != orderId)
            } else {
                this.orders = this.orders.filter(order => order.id != orderId)
            }

            const orderName = this.currentDeleteItem.name
            this.snackbar = true
            this.currentTime = 0
            this.snackbarText = `Заказ ${orderName}`

            this.syncPbar()
        },

        deleteOrder() {
            console.log('this.currentDeleteItem', this.currentDeleteItem)
            // const userRole = this.$store.state.user.role
            // const date = new Date().toISOString()
            const order = this.currentDeleteItem
            //удаляем индекс от snackbar
            delete order.indexOrder
            // order.date_delete = date
            // order.who_delete = userRole
            //передаем статус удаления
            order.status_delete = config.order.status.deleted

            if (!order.isCombined) {
                this.$store
                    .dispatch('order_delete', order.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order delete ok')
                            //можно ли тут так оставить? или лучше сделать последовательно друг за другом?
                            this.notifyDeleteOrder()
                            this.getDeletedAllTypesOrders()
                            this.disBtn = false
                        } else {
                            console.log('order delete fail')
                        }
                    })
                    .catch(err => {
                        console.log('order_delete error', err.name, err.message)
                    })
            } else {
                this.$store
                    .dispatch('multi_order_delete', order.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('multi_order delete ok', res.data)
                            //можно ли тут так оставить? или лучше сделать последовательно друг за другом?
                            this.notifyDeleteOrder()
                            this.getDeletedAllTypesOrders()
                            this.disBtn = false
                        } else {
                            console.log('multi_order delete fail')
                        }
                    })
                    .catch(err => {
                        console.log('multi_order_delete error', err.name, err.message)
                    })
            }
        },

        onOrderClose() {
            console.log('order close')
        },

        onOrderDelete() {
            this.currentDeleteItem = this.currentItem
            this.snackbarStart()
        },

        async onOrderCopy(order) {
            console.log('onOrderCopy', order)
            this.dialogHeader = `Копия заказа`
            this.copyOrder = order
            this.dialogCreate = true
        },

        async onOrderCreate(order) {
            console.log('onOrderCreate', order)
            this.loading = true

            const point = this.points.find(p => p.id == order.point_id)
            if (point) {

                order.source = config.order.source.web
                order.status = config.order.status.new
                if (order.options.marketingSource) {
                    order.options.marketingSource = null
                }
                if (order.options.orderType) {
                    order.options.orderType = null
                }

                const res = await this.$store.dispatch('order_create', { order, point_name: point.name })
                if (res && res.data.success) {
                    const order = JSON.parse(JSON.stringify(res.data.order))
                    console.log('order create ok', order)

                    if (!order.options) {
                        order.options = {}
                    }

                    order.courier_name = '--'
                    this.orders.push(order)
                    // this.orders.splice(0, 0, order)
                    // this.orders = JSON.parse(JSON.stringify(this.orders))
                    this.$store
                        .dispatch('guest_get', {
                            id: order.guest_id
                        })
                        .then(res => {
                            if (res.data.success) {
                                order.guest_name = res.data.guest.name
                                order.guest_phone = res.data.guest.phone
                                console.log('GUEST LOADING OK')
                            }
                            this.loading = false
                        })
                        .catch(err => {
                            console.error('error 1', err.name, err.message)
                            this.loading = false
                        })
                    this.notifyToast(`Новый заказ ${order.name} создан успешно`, { color: 'success', x: 'right', y: 'top', timeout: 4000 })
                } else {
                    this.notifyToast(`Не удалось создать заказ - ошибка (${res.data.msg})`, { color: 'error', x: 'right', y: 'top', timeout: 4000 })
                    this.loading = false
                }
            } else {
                this.notifyToast('Не удалось создать заказ - не найдена точка', { color: 'error', x: 'right', y: 'top', timeout: 4000 })
                this.loading = false
            }
        },

        notifyCancelOrder(newStatus) {
            const order = this.currentItem
            const data = {
                orderId: order.id,
                newStatus,
            }
            this.$store
                .dispatch('order_cancel_notify', data)
                .then(res => {
                    if (res.data.success) {
                        console.log('notify ok')
                    }
                })
                .catch(err => {
                    console.log('notify false', err.name, err.message)
                })
        },

        onOrderSave(data) {
            const { order, manualCompleteFlag } = data

            if (order.isCombined) {
                // У мультизаказа может поменяться только время доставки и забора
                const { date_ready, date_delivery, id } = order

                this.$store
                    .dispatch('multi_order_set', {
                        date_ready,
                        date_delivery,
                        id,
                        orders: order.orders,
                    })
                    .then(res => {
                        if (res.data.success) {
                            console.log('multiOrder saved ok')

                            this.updateData()
                        } else {
                            console.log('multiOrder saved fail')
                        }
                    })
                    .catch(err => {
                        console.log('multi_order_set error', err.name, err.message)
                    })


                return
            } else {
                // тут есть нюанс. была ситуация: заказ в статусе создается, открыли этот диалог, в боте поменяли статус заказа на новый, диалог закрыли -> статус заказа обновился с новый на создается и при этом заказ пропадает в боте в списке необработанных заказов, теперь сюда внесены изменения по проверке изменения полей и обновлению заказа в апи только при изменении этих полей. Но это не решает проблему до конца, так как если поля поменяют, то произойдет так же пропажа заказа в боте. решение - обновлять только поля guest, finish_addr и fee_courier, но для этого требуется в api поменять функцию updateOrder - принято еще другое решение: делается запрет на открытие диалога при заказе со статусом формируется

                // ! а еще произошла интересней проблема - заказ открыли для редактирования, у заказа status = 2, в этот момент курьер доставил заказ (сделал status = 3), в форме поменяли адрес и комментарий, нажали сохранить и перезаписали status на недоставленный, потом курьеру пришлось еще раз закрывать заказ. это привело к тому, что заказ стал сильно запоздалым и отправились 2 смс с отзывом

                // ! проверить как работает этот fix

                let orderSave = {
                    id: order.id,
                }

                let courierChangeFlag = false

                if (this.currentItem.options != order.options) {
                    this.currentItem.options = order.options
                    orderSave.options = order.options
                    this.changeFlag = true
                }
                if (this.currentItem.coordinates != order.coordinates) {
                    this.currentItem.coordinates = order.coordinates
                    orderSave.coordinates = order.coordinates
                    this.changeFlag = true
                }
                if (this.currentItem.date_ready != order.date_ready) {
                    this.currentItem.date_ready = order.date_ready
                    orderSave.date_ready = order.date_ready
                    this.changeFlag = true
                }
                if (this.currentItem.date_delivery != order.date_delivery) {
                    this.currentItem.date_delivery = order.date_delivery
                    orderSave.date_delivery = order.date_delivery
                    this.changeFlag = true
                }
                if (this.currentItem.guest != order.guest) {
                    this.currentItem.guest = order.guest
                    orderSave.guest = order.guest
                    this.changeFlag = true
                }
                if (this.currentItem.finish_addr != order.finish_addr) {
                    this.currentItem.finish_addr = order.finish_addr
                    orderSave.finish_addr = order.finish_addr
                    this.changeFlag = true
                }
                if (this.currentItem.descr != order.descr) {
                    this.currentItem.descr = order.descr
                    orderSave.descr = order.descr
                    this.changeFlag = true
                }
                if (this.currentItem.coordinates != order.coordinates) {
                    this.currentItem.coordinates = order.coordinates
                    orderSave.coordinates = order.coordinates
                    this.changeFlag = true
                }
                if (this.currentItem.courier_tid != order.courier_tid) {
                    this.currentItem.courier_tid = order.courier_tid
                    this.currentItem.status = order.status
                    this.currentItem.date_take = order.date_ready
                    this.currentItem.date_complete = order.date_delivery
                    orderSave.courier_tid = order.courier_tid
                    orderSave.status = order.status
                    orderSave.date_take = order.date_ready
                    orderSave.date_complete = order.date_delivery
                    this.changeFlag = true
                    courierChangeFlag = true
                    this.currentItem.courier_name = this.couriers_list.find(c => c.tid == this.currentItem.courier_tid).name
                    if (this.currentItem.courier_name == '< Не назначен >') {
                        this.currentItem.courier_name = '--'
                    }
                }

                const pay_cost = parseInt(order.pay_cost)
                if (!isNaN(order.pay_cost) && pay_cost > 0) {
                    if (this.currentItem.pay_cost != pay_cost) {
                        this.currentItem.pay_cost = pay_cost
                        orderSave.pay_cost = pay_cost
                        this.changeFlag = true
                    }
                }

                const fee_courier = parseInt(order.fee_courier)
                if (fee_courier || fee_courier == 0) {
                    if (order.date_complete) {
                        this.ordersFeeCourier += fee_courier - this.currentItem.fee_courier
                    }
                    this.currentItem.fee_courier = fee_courier
                    orderSave.fee_courier = fee_courier
                    this.changeFlag = true
                }

                const fee_point = parseInt(order.fee_point)
                if (fee_point || fee_point == 0) {
                    if (order.date_complete) {
                        this.ordersFeePoint += fee_point - this.currentItem.fee_point
                    }
                    this.currentItem.fee_point = fee_point
                    orderSave.fee_point = fee_point
                    this.changeFlag = true
                }

                if (this.changeFlag) {
                    // orderSave = {...order,...orderSave}  
                    // orderSave.status = config.order.status.passed
                    console.log('save order1', orderSave)
                    this.$store
                        .dispatch('order_set', orderSave)
                        .then(res => {
                            if (res.data.success) {
                                if (manualCompleteFlag) {
                                    this.notifyCancelOrder(false)
                                }
                                this.updateData()
                                console.log('order saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                            this.changeFlag = false
                        })
                        .catch(err => {
                            console.log('order_set error', err.name, err.message)
                        })
                }
            }
        },

        onClickOrderCreate() {
            this.dialogHeader = `Новый заказ`
            this.copyOrder = null
            this.dialogCreate = true
        },

        onClickOrderEdit(item) {
            console.log('onClickOrderItem')
            if (this.dialogStatus || this.guestNameClick || this.guestCreateClick) return
            if (item.status == config.order.status.forming) {
                this.dialogHeader = `Заказ ${item.name} создается...`
            } else {
                this.dialogHeader = `${item.isCombined ? `Мультизаказ ${item.name}` : `Заказ ${item.name}`}`
            }
            this.currentItem = item
            let courier = this.couriers.find(c => c.tid == this.currentItem.courier_tid)
            this.courierTariff = courier && courier.settings && courier.settings.tariff ? +courier.settings.tariff : 0
            console.log('this.dialogEdit = true')
            this.dialogEdit = true
        },

        onClickOrderStatus(item) {
            this.dialogHeader = `Изменить статус для ${item.name}`
            this.dialogContent = `Текущий статус заказа: <span style="color: ${this.orderStatusColorHtml(item.status)};">${this.orderStatusStr2(item)}</span>`
            this.currentItem = item
            this.dialogStatus = true
        },

        orderStatusStr2(item) {
            return this.orderStatusStr(item.status)
        },

        onClickOrderNew() {
            let courier = this.couriers.find(courier => courier.tid == this.currentItem.courier_tid)
            if (courier) {
                if (courier.act_ord) {
                    courier.act_ord--
                }
                if (courier.act_ord == 0) {
                    courier.status = config.user.status.free
                }
                this.$store.dispatch('user_set', courier).then(res => {
                    if (res.data.success) {
                        console.log('user saved ok')
                    } else {
                        console.log('order saved fail')
                    }
                })
            }

            this.currentItem.status = config.order.status.new
            this.currentItem.courier_tid = null
            this.currentItem.date_take = null
            this.currentItem.date_complete = null

            this.notifyCancelOrder(true)

            this.$store
                .dispatch('order_set', this.currentItem)
                .then(res => {
                    if (res.data.success) {
                        console.log('order saved ok')
                        this.orders = this.orders.map((order, i) => {
                            if (order.id == this.currentItem.id) {
                                order.status = this.currentItem.status
                                order.courier_tid = this.currentItem.courier_tid
                            }
                            return order
                        })
                    } else {
                        console.log('order saved fail')
                    }
                    this.dialogStatus = false
                })
                .catch(err => {
                    console.log('order_set error', err.name, err.message)
                    this.dialogStatus = false
                })
        },

        onClickOrderComplete() {
            let courier = this.couriers.find(courier => courier.tid == this.currentItem.courier_tid)
            // console.log('this.currentItem', this.currentItem)
            // console.log('courier', courier)

            if (courier) {
                if (courier.act_ord) {
                    courier.act_ord--
                }
                if (courier.act_ord == 0) {
                    courier.status = config.user.status.free
                }

                this.$store.dispatch('user_set', courier).then(res => {
                    if (res.data.success) {
                        console.log('user saved ok')
                    } else {
                        console.log('order saved fail')
                    }
                })
            }

            this.currentItem.status = config.order.status.complete
            this.currentItem.date_take = this.currentItem.date_ready
            this.currentItem.date_complete = this.currentItem.date_delivery

            this.$store
                .dispatch('order_set', this.currentItem)
                .then(res => {
                    if (res.data.success) {
                        console.log('order saved ok')
                        this.orders = this.orders.map((order, i) => {
                            if (order.id == this.currentItem.id) {
                                order.status = this.currentItem.status
                                order.date_complete = this.currentItem.date_complete
                            }
                            return order
                        })
                        this.notifyCancelOrder(false)
                    } else {
                        console.log('order saved fail')
                    }
                    this.dialogStatus = false
                })
                .catch(err => {
                    console.log('order_set error', err.name, err.message)
                    this.dialogStatus = false
                })
        },

        onClickOrderDelete() {
            this.currentDeleteItem = this.currentItem
            this.dialogStatus = false
            this.snackbarStart()
        },

        onClickChangeDate(arg) {
            this.d1 = this.getDate1StringFromDate(this.dates[0])
            this.d2 = this.getDate2StringFromDate(this.dates[1])
            this.updateData()
        },

        onClickDownloadData() {
            let operator_tid
            let point_id
            if (!this.userIsPoint) {
                operator_tid = this.$store.state.user.tid
                point_id = this.filter_point
            } else {
                operator_tid = this.$store.state.user.operator_tid
                point_id = this.$store.state.user.point_id
            }
            console.log('settings', this.$store.state.settings)
            let url = `${config.api.delivery}orders_export?tz=${this.$store.state.settings.tz}&tz_string=${this.$store.state.settings.tz_string}&operator_tid=${operator_tid}&date1=${this.d1}&date2=${this.d2}&point_id=${point_id}&courier=${this.filter_courier}&mode=${this.filter_order_mode}&deliveryTime=true`
            if (!this.userIsPoint) {
                url += '&operator=1'
            }
            window.location.href = url
        },

        onClickToday() {
            this.dates[0] = moment().format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')

            this.d1 = this.getDate1String()
            this.d2 = this.getDate2String()
            this.updateData()
        },

        onClickYesterday() {
            this.dates[0] = moment().subtract(1, 'd').format('YYYY-MM-DD')
            this.dates[1] = moment().subtract(1, 'd').format('YYYY-MM-DD')

            this.d1 = this.getDate1String(-1)
            this.d2 = this.getDate2String(-1)
            this.updateData()
        },

        onClickMonth() {
            this.dates[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')

            this.d1 = this.getDate1String(-30)
            this.d2 = this.getDate2String()
            this.updateData()
        },

        onClickUpdate() {
            this.updateData()
        },

        getGuestStr(guest) {
            let splittedStr = guest.split('Телефон:')
            return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 17) : guest
        },

        updateData() {
            this.loading = true
            this.couriers = []
            let loading_count = 0

            console.log('UPDATE ORDERS START')

            this.$store
                .dispatch('all_orders_date', {
                    date1: this.d1,
                    date2: this.d2,
                    point_id: this.userIsPoint ? this.$store.state.user.point_id : this.filter_point,
                    courier: this.filter_courier,
                    mode: this.filter_order_mode,
                    deliveryTime: true,
                })
                .then(res => {
                    if (res.data.success) {
                        this.orders = res.data.orders.reverse()
                        this.multiOrders = res.data.multiOrders.reverse()

                        console.log('orders', this.orders)
                        console.log('multi orders', this.multiOrders)
                        
                        let fee_courier = 0
                        let fee_point = 0

                        let takeSum = 0
                        let takeCount = 0
                        let deliverySum = 0
                        let deliveryCount = 0

                        this.averageTimeTake = 0
                        this.averageTimeDelivery = 0

                        this.maximumTimeTake = 0
                        this.maximumTimeDelivery = 0

                        if (this.orders.length && this.multiOrders.length) {
                            this.minimumTimeTake = 1000000
                            this.minimumTimeDelivery = 1000000
                        } else {
                            this.minimumTimeTake = 0
                            this.minimumTimeDelivery = 0
                        }

                        for (let order of [...this.orders, ...this.multiOrders]) {
                            if (order.date_take) {
                                if (order.mode === '🔥 Ближайший') {
                                    const t = moment(order.date_take).diff(order.date, "minutes")
                                    if (t > 0 && t < 240) { // от 1 минуты до 4 часов только учитываем
                                        if (t < this.minimumTimeTake) this.minimumTimeTake = t
                                        if (t > this.maximumTimeTake) this.maximumTimeTake = t
                                        takeSum += t
                                        takeCount += 1
                                    }
                                }
                                if (order.date_complete) {
                                    const t = moment(order.date_complete).diff(order.date_take, "minutes")
                                    if (t > 0 && t < 240) { // от 1 минуты до 4 часов только учитываем
                                        if (t < this.minimumTimeDelivery) this.minimumTimeDelivery = t
                                        if (t > this.maximumTimeDelivery) this.maximumTimeDelivery = t
                                        deliverySum += t
                                        deliveryCount += 1
                                    }
                                }
                            }

                            if (order.date_complete) {
                                const f_courier = parseInt(order.fee_courier)
                                const f_point = parseInt(order.fee_point)
                                if (f_courier) fee_courier += f_courier
                                if (f_point) fee_point += f_point
                            }

                            if (order.status != config.order.status.new) {
                                this.couriers.push({
                                    tid: order.courier_tid,
                                    full_name: this.userIsPoint ? order.courier_name.split(' ')[0] : order.courier_name,
                                })
                            }
                        }

                        if (takeCount) {
                            this.averageTimeTake = +(takeSum / takeCount).toFixed(0)
                        } else {
                            this.minimumTimeTake = 0
                        }
                        if (deliveryCount) {
                            this.averageTimeDelivery = +(deliverySum / deliveryCount).toFixed(0)
                        } else {
                            this.minimumTimeDelivery = 0
                        }

                        this.ordersFeeCourier = fee_courier
                        this.ordersFeePoint = fee_point

                        if (this.userIsPoint) {
                            this.couriers_list = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                            this.couriers_list.splice(0, 0, { tid: 'null', name: '< Не назначен >' })

                            this.filter_couriers = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                            this.filter_couriers.splice(0, 0, { tid: 0, name: '< Все >' })
                            this.filter_couriers.splice(1, 0, { tid: 'null', name: '< Не назначен >' })
                        }

                        this.orderStatuses[config.order.status.forming] = 0
                        this.orderStatuses[config.order.status.new] = 0
                        this.orderStatuses[config.order.status.passed] = 0
                        this.orderStatuses[config.order.status.way_point] = 0
                        this.orderStatuses[config.order.status.way_guest] = 0
                        this.orderStatuses[config.order.status.complete] = 0

                        this.orders.forEach(order => {
                            this.orderStatuses[order.status]++
                        })

                        this.multiOrders.forEach(order => {
                            this.orderStatuses[order.status]++
                        })

                        this.ordersComplete = this.orderStatuses[config.order.status.complete]

                        this.$store
                            .dispatch('guests_get', {
                                date1: this.d1,
                                date2: this.d2,
                                point_id: this.userIsPoint ? this.$store.state.user.point_id : this.filter_point,
                                // dateVisit: true,
                            })
                            .then(res => {
                                if (res.data.success) {
                                    const guests = res.data.guests
                                    // console.log('guests', guests)
                                    for (let order of [...this.orders, ...this.multiOrders]) {
                                        if (order.guest_id) {
                                            const guest = guests.find(g => g.id == order.guest_id)
                                            if (guest) {
                                                order.guest_name = guest.name
                                                order.guest_phone = guest.phone
                                            }
                                        }
                                    }
                                }
                                loading_count++
                                console.log('GUESTS LOADING OK')
                            })
                            .catch(err => {
                                console.error('error 1', err.name, err.message)
                                loading_count++
                            })
                    } else {
                        // this.ordersCountToday = 0
                        this.ordersComplete = 0
                        this.ordersFeeCourier = 0
                        this.ordersFeePoint = 0
                    }
                    loading_count++
                    console.log('ORDERS LOADING OK')
                })
                .catch(err => {
                    console.error('error 2', err.name, err.message)
                    loading_count++
                })

            this.$store
                .dispatch('points_get')
                .then(res => {
                    if (res.data.success) {
                        this.points = res.data.points

                        // console.log('points', this.points)
                    }

                    this.filter_points = this.points.map(p => ({ id: p.id, name: p.name, address: p.address }))
                    this.filter_points.splice(0, 0, { id: 0, name: '< Все >' })
                    loading_count++
                    console.log('POINTS LOADING OK')
                })
                .catch(err => {
                    console.error('error 3', err.name, err.message)
                    loading_count++
                })

            if (!this.userIsPoint) {
                this.$store
                    .dispatch('couriers_get')
                    .then(res => {
                        if (res.data.success) {
                            this.couriers = res.data.couriers
                        }

                        this.couriers_list = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                        this.couriers_list.splice(0, 0, { tid: 'null', name: '< Не назначен >' })

                        this.filter_couriers = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                        this.filter_couriers.splice(0, 0, { tid: 0, name: '< Все >' })
                        this.filter_couriers.splice(1, 0, { tid: 'null', name: '< Не назначен >' })
                        // console.log('filter_couriers', this.filter_couriers)
                        // this.loading = false
                        loading_count++
                        console.log('COURIERS LOADING OK')
                    })
                    .catch(err => {
                        console.error('error 4', err.name, err.message)
                        loading_count++
                    })
            } else {
                loading_count++
                console.log('COURIERS LOADING SKIP')
            }

            // получение удаленных заказов
            this.getDeletedAllTypesOrders()

            const loadingControl = () => {
                console.log('loading_count', loading_count)
                if (loading_count < 4) {
                    setTimeout(() => loadingControl(), 500)
                } else {
                    this.loading = false
                }
            }
            loadingControl()
        },

        rowItemClass(item) {
            if (item.orders) return 'combined-order'

            return ''
        },

        closeCalendar() {
            this.menu_calendar = false
        },

        clickFeeUpdate() {
            this.promptFeeUpdate = true
        },

        promptFeeUpdateYes() {
            this.promptFeeUpdate = false
            this.feeUpdate()
        },

        updateDarallDates() {
            if (this.currentItem.mode === '🔥 Ближайший') {
                this.darallDateReady = this.timeTake ? moment().add(this.timeTake, 'seconds') : null
                this.darallDateDelivery = this.timeDelivery && this.darallDateReady ? moment(this.darallDateReady).add(this.timeDelivery, 'seconds') : null
            } else {
                this.darallDateReady = this.currentItem.date_ready ? moment(this.currentItem.date_ready) : null
                this.darallDateDelivery = this.currentItem.date_delivery ? moment(this.currentItem.date_delivery) : null
            }
        },

        async onDarallSend(data) {
            if (!data.order.isCombined) {
                console.log('onDarallSend', data)
                this.promptDarallSend = true
                this.currentItem = data.order
                this.orderPointDarall = data.orderPointDarall
                this.darallPriceCourier = 0
                this.darallPricePoint = 0
                this.darallDateReady = null
                this.darallDateDelivery = null
                if (this.currentItem.options.distance) {
                    this.loadingDarallSend = true
                    this.$store
                        .dispatch('price_distance', {
                            operator_tid: config.darall_operator_tid,
                            distance: this.currentItem.options.distance,
                        })
                        .then(res => {
                            if (res.data.success) {
                                this.darallPriceCourier = res.data.priceCourier ?? 0
                                this.darallPricePoint = res.data.pricePoint ?? 0
                                this.timeTake = res.data.timeTake
                                this.timeDelivery = res.data.timeDelivery
                                this.updateDarallDates()
                            }
                            this.loadingDarallSend = false
                        })
                        .catch(err => {
                            this.loadingDarallSend = false
                        })
                }
            } else {
                this.promptDarallSend = true
                this.currentItem = data.order
                this.multiOrderPointsDarall = data.multiOrderPointsDarall
                this.darallPriceCourier = 0
                this.darallPricePoint = 0
                this.darallDateReady = null
                this.darallDateDelivery = null
                if (this.currentItem.options.distance) {
                    this.loadingDarallSend = true
                    this.$store
                        .dispatch('price_distance', {
                            operator_tid: config.darall_operator_tid,
                            distance: this.currentItem.options.distance,
                        })
                        .then(res => {
                            if (res.data.success) {
                                this.darallPriceCourier = res.data.priceCourier 
                                    ? res.data.priceCourier + (this.$store.state.settings.extraPointFee ? this.$store.state.settings.extraPointFee * (this.currentItem.orders.length - 1) : 0) 
                                    : 0
                                this.darallPricePoint = res.data.pricePoint 
                                    ? res.data.pricePoint + (this.$store.state.settings.extraPointFee ? this.$store.state.settings.extraPointFee * (this.currentItem.orders.length - 1) : 0) 
                                    : 0
                                this.timeTake = res.data.timeTake
                                this.timeDelivery = res.data.timeDelivery
                                this.updateDarallDates()
                            }
                            this.loadingDarallSend = false
                        })
                        .catch(err => {
                            this.loadingDarallSend = false
                        })
                }
            }
        },

        promptDarallSendYes() {
            if (!this.currentItem.isCombined) this.promptDarallSendYesOrder()
            else this.promptDarallSendYesMultiOrder()
        },

        promptDarallSendYesMultiOrder() {
            this.promptDarallSend = false
            this.dialogEdit = false

            if (this.multiOrderPointsDarall.length === this.currentItem.orders.length) {
                this.currentItem.courier_tid = null
                this.currentItem.date_accept = null
                this.currentItem.date_take = null
                this.currentItem.date_complete = null
                this.currentItem.status = config.order.status.new

                this.updateDarallDates()
                this.currentItem.date_ready = this.darallDateReady.toDate()
                this.currentItem.date_delivery = this.darallDateDelivery.toDate()
                
                for (let i = 0; i < this.currentItem.orders.length; i += 1) {
                    const index = i
                    const order = this.currentItem.orders[i]

                    if (index < this.currentItem.orders.length - 1) {
                        this.currentItem.orders[i].fee_courier = +((+order.pay_cost / +this.currentItem.pay_cost) * this.darallPriceCourier).toFixed(0)
                        this.currentItem.orders[i].fee_point = +((+order.pay_cost / +this.currentItem.pay_cost) * this.darallPricePoint).toFixed(0)
                    } else {
                        this.currentItem.orders[i].fee_courier = +this.darallPriceCourier - +this.currentItem.orders.reduce((acc, e, index) => {
                            if (index !== this.currentItem.orders.length - 1) acc += e.fee_courier

                            return acc
                        }, 0)

                        this.currentItem.orders[i].fee_point = +this.darallPricePoint - +this.currentItem.orders.reduce((acc, e, index) => {
                            if (index !== this.currentItem.orders.length - 1) acc += e.fee_point

                            return acc
                        }, 0)
                    }
                }

                const textPartnerSend = `отправлен из личного кабинета партнера (${this.timeToString()})`

                this.currentItem.orders = this.currentItem.orders.map(e => {
                    const newPoint = this.multiOrderPointsDarall.find(el => el.orderId === e.id)

                    return {
                        ...e,
                        oldPointUuid: e.pointIdUuid,
                        point_id: typeof newPoint !== 'undefined' ? +newPoint.darallPointId : null,
                        point_tid: null,
                        courier_tid: null,
                        date_accept: null,
                        date_take: null,
                        date_complete: null,
                        status: config.order.status.new,
                        date_ready: this.currentItem.date_ready,
                        date_delivery: this.currentItem.date_delivery,
                        descr: e.descr && e.descr !== '' ? e.descr += `, ${textPartnerSend}` : textPartnerSend
                    }
                })

                this.$store
                    .dispatch('send_multi_order', this.currentItem)
                    .then(res => {
                        if (res.data.success) {
                            console.log('res.data', res.data)
                            this.updateData()
                            this.notifyToast('Мультизаказ отправлен оператору Darall успешно.', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            this.notifyToast('Не удалось сохранить отправляемый мультизаказ.', { color: 'error', x: 'right', y: 'top', timeout: 5000 })
                        }
                    })
                    .catch(err => {
                        console.log('order_set error', err.name, err.message)
                    })
            } else {
                this.notifyToast('Не удалось определить точку у оператора Darall, которой отправляется заказ.', { color: 'error', x: 'right', y: 'top', timeout: 5000 })
            }
        },

        promptDarallSendYesOrder() {
            this.promptDarallSend = false
            this.dialogEdit = false

            if (this.orderPointDarall) {
                this.currentItem.point_id = this.orderPointDarall
                this.currentItem.point_tid = null
                this.currentItem.courier_tid = null
                this.currentItem.date_accept = null
                this.currentItem.date_take = null
                this.currentItem.date_complete = null
                this.currentItem.fee_courier = this.darallPriceCourier
                this.currentItem.fee_point = this.darallPricePoint
                this.currentItem.status = config.order.status.new

                this.updateDarallDates()
                this.currentItem.date_ready = this.darallDateReady.toDate()
                this.currentItem.date_delivery = this.darallDateDelivery.toDate()

                const textPartnerSend = `отправлен из личного кабинета партнера (${this.timeToString()})`

                if (this.currentItem.descr && this.currentItem.descr !== '') {
                    this.currentItem.descr += `, ${textPartnerSend}`
                } else {
                    this.currentItem.descr = textPartnerSend
                }

                this.$store
                    .dispatch('order_set', this.currentItem)
                    .then(res => {
                        if (res.data.success) {
                            this.updateData()
                            console.log('order saved ok')
                            this.notifyToast('Заказ отправлен оператору Darall успешно.', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            console.log('order saved fail')
                            this.notifyToast('Не удалось сохранить отправляемый заказ.', { color: 'error', x: 'right', y: 'top', timeout: 5000 })
                        }
                    })
                    .catch(err => {
                        console.log('order_set error', err.name, err.message)
                    })
            } else {
                this.notifyToast('Не удалось определить точку у оператора Darall, которой отправляется заказ.', { color: 'error', x: 'right', y: 'top', timeout: 5000 })
            }
        },

        feeUpdate() {
            this.loading = true
            this.$store
                .dispatch('orders_fee_calculate', {
                    date1: this.d1,
                    date2: this.d2,
                    // mode: 0,
                })
                .then(res => {
                    this.loading = false
                    if (res.data.success) {
                        this.updateData()
                    }
                })
                .catch(err => {
                    console.log('2 error', err.name, err.message)
                    this.loading = false
                })
        },

        distance(d) {
            if (!d) d = 0
            // console.log('distance', d)
            const distance = parseFloat(d)
            if (distance) {
                return (distance / 1000).toFixed(1) + ' км'
            }
            return '--'
        },

        marketingSource(m) {
            if (m) {
                return m
            }
            return '--'
        },
    },
}
</script>

<style lang="stylus" scoped>

    .v-item-group.v-expansion-panels {
        &,
        & .v-expansion-panel,
        & .v-expansion-panel-header,
        & .v-expansion-panel-content {
            transition: none !important;
        }
    }

    .btn-create {
        position: fixed;
        right: 30px;
        bottom: 30px;
    }

    .combined-order
        background-color: #fbe6ff
    .combined-order:hover
        background-color: #eed3f3 !important
    .rowItemDisable
        cursor default !important
        background #fff !important

        [role="button"]
            cursor default !important

    .orders
        padding-top 10px !important
        .v-expansion-panel-header {
            font-size: 0.92rem;
            font-weight: 400;
            padding: 8px;
            width: auto;
            min-height: 0;
        }
        .v-expansion-panel-content {
            font-size: 0.92rem;
            font-weight: 400;
            padding: 8px;
            padding-top: 0;
        }
        .v-expansion-panel--active > .v-expansion-panel-header {
            min-height: 0;
            padding-bottom: 5px;
            background-color: #eee;
        }

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    white-space nowrap
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px
            &:hover
                cursor pointer

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                margin-left 0
                cursor pointer
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline
</style>
